import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import AuthContext from "../context/AuthContext";

const LoginBeforeModel = ({ show, onHide }) => {
  const {
    beforeModalData,
    beforeModal,
    loginUser,
    setBeforeModalData,
    setBeforeModal,
  } = useContext(AuthContext);
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body
        style={{
          background: "linear-gradient(#535353 0%, #000000 80%)",
          color: "white",
          fontSize: "15px",
          fontWeight: "600",
          lineHeight: "24px",
        }}
      >
        <div className="d-flex flex-column align-items-center text-center">
          <p
            style={{
              borderBottom: "1px solid white",
              width: "100%",
              paddingBottom: "10px",
            }}
          >
            Non-Gambling Territories.
          </p>
          <div
            style={{
              borderBottom: "1px solid white",
              width: "100%",
              paddingBottom: "10px",
            }}
          >
            <p>
              Connecting to our site from non gambling countries, it will be
              User's responsibility to ensure that their use of the service is
              lawful.
            </p>
            <h4>Underage gambling is prohibited.</h4>
          </div>
          <p
            style={{
              paddingTop: "10px",
            }}
          >
            Please confirm if you are 18 years old and above as of today.{" "}
          </p>
          <div>
            <Button
              onClick={() => {
                loginUser(beforeModalData);
                setBeforeModal(false);
              }}
              style={{
                background: "white",
                color: "black",
                fontWeight: "600",
                fontSize: "14px",
                padding: "5px 16px",
              }}
            >
              Confirm
            </Button>{" "}
            <Button
              onClick={() => {
                setBeforeModalData({});
                setBeforeModal(false);
              }}
              style={{
                background: "black",
                color: "white",
                border: "1px solid white",
                fontWeight: "600",
                fontSize: "14px",
                padding: "5px 16px",
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginBeforeModel;
