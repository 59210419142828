import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import AuthContext from "../context/AuthContext";
import { Button, Form } from "react-bootstrap";
import logoSkyexchange from "../assets/images/home/dream444.png";
import { useNavigate } from "react-router-dom";
const LoginPage = () => {
  let { loginUser, user } = useContext(AuthContext);
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({});
  const onSubmit2 = (data) => {
    loginUser(data);
    reset2();
  };

  const navigate = useNavigate();
  return (
    <div className="login-page">
      <p onClick={() => navigate("/")} className="login-page-cross">
        X
      </p>
      <div className="login-page-inner">
        <img
          src={logoSkyexchange}
          style={{ width: "60%", marginBottom: "30px" }}
        />
        <div className="login_main">
          <Form onSubmit={handleSubmit2(onSubmit2)}>
            <Form.Group className="mb-2" controlId="formBasicEmail2">
              <Form.Control
                type="text"
                placeholder="Username"
                className={errors2.username ? "is-invalid" : ""}
                {...register2("username", {
                  required: "Please enter Username",
                })}
              />
              {errors2.username && errors2.username.message && (
                <label className="invalid-feedback text-left">
                  {errors2.username.message}
                </label>
              )}
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicPassword">
              <Form.Control
                type="password"
                placeholder="Password"
                className={errors2.password ? "is-invalid" : ""}
                {...register2("password", {
                  required: "Please enter password",
                })}
              />
              {errors2.password && errors2.password.message && (
                <label className="invalid-feedback text-left">
                  {errors2.password.message}
                </label>
              )}
            </Form.Group>

            <div>
              <Button type="submit" className="login_btn">
                Login{" "}
                {/* <img className="icon-login ms-1" src={loginSVG} alt="" /> */}
              </Button>

              {/* <a
                href="#"
                id="loginBtn"
                className="login_btn"
                onClick={handleShow}
              >
                Login
                <img
                  className="icon-login ms-1"
                  src="assets/images/home/login.svg"
                />
              </a> */}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
