import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import moment from "moment";
import Layout from "./Layout";
import helpers from "../utils/helpers";
import obj, { headerData } from "../utils/constants";
import { toast } from "wc-toast";
import ReactPaginate from "react-paginate";
import { startCase } from "lodash";
const BetHistoryNew = () => {
  const [key, setKey] = useState("Exchange");
  const location = useLocation();
  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  const [isLoader, setLoader] = useState(false);
  const [filter, setFilter] = useState({
    betType: "betfair",
    status: "completed",
    fromPeriod: previousDate,
    toPeriod: currentDate,
    filterByDay: "",
    page: 1,
    limit: 10,
  });
  const [data, setData] = useState([]);
  const getData = async (type) => {
    setLoader(true);
    if (filter.filterByDay != "") {
      if (filter.filterByDay == "today") {
        filter.fromPeriod = currentDate;
        filter.toPeriod = currentDate;
      }
      if (filter.filterByDay == "yesterday") {
        filter.fromPeriod = previousDate;
        filter.toPeriod = currentDate;
      }
    }
    let obj;
    if (type !== "reset") {
      obj = {
        ...filter,
        betType:
          key == "Exchange"
            ? "betfair"
            : key == "FancyBet"
            ? "fancy"
            : key == "Sportsbook"
            ? "sportBook"
            : key == "BookMaker"
            ? "bookmaker"
            : key == "Tie"
            ? "tie"
            : key == "Toss"
            ? "toss"
            : key == "parlay"
            ? "parlay"
            : "casino",
      };
    } else {
      obj = {
        status: "completed",
        toPeriod: "",
        fromPeriod: "",
        filterByDay: "",
        betType:
          key == "Exchange"
            ? "betfair"
            : key == "FancyBet"
            ? "fancy"
            : key == "Sportsbook"
            ? "sportBook"
            : key == "BookMaker"
            ? "bookmaker"
            : key == "Tie"
            ? "tie"
            : key == "Toss"
            ? "toss"
            : key == "parlay"
            ? "parlay"
            : "casino",
      };
    }

    const { status, data: response_users } = await apiGet(
      apiPath.getHistory,
      obj
    );
    if (status === 200) {
      if (response_users.success) {
        setLoader(false);
        setData(response_users.results);
      } else {
        setLoader(false);
        setData([]);
      }
    }
  };
  //   useEffect(() => {
  //     if (filter.filterByDay != "") {
  //       getData();
  //     }
  //   }, [filter.filterByDay]);
  //   useEffect(() => {
  //     getData();
  //   }, [key]);
  const [pageCount, setPageCount] = useState(0);
  const [viewpage, setViewPage] = useState(0);
  const handlePageClick = (event) => {
    setFilter((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
    setViewPage(event.selected);
  };
  //   useEffect(() => {
  //     setPageCount(data?.totalPages || []);
  //   }, [data]);
  //   useEffect(() => {
  //     setViewPage(filter.page ? filter.page - 1 : 0);
  //   }, [filter]);
  return (
    <Layout>
      <div className="d-flex justify-content-center align-items-center pt-3">
        <div
          className="account-table d-flex flex-column align-items-center"
          style={{ width: "98%" }}
        >
          <Row
            style={{
              background: "#e0e6e6",
              padding: "10px 10px 0",
              border: "1px solid #000501",
              borderRadius: "5px",
              marginBottom: "10px",
              paddingBottom: "10px",
              gap:".5rem"
            }}
            
            className="container"
          >
            {" "}
            <Col lg={3} sm={3}>
              <Form.Select
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
                value={filter.status}
                aria-label="Default select example"
              >
                <option value="">Data Source</option>

                <option value="completed">Live Data</option>
                <option value="suspend">Backup Data</option>
                <option value="voided">Old Data</option>
              </Form.Select>
            </Col>
            <Col lg={3} sm={3}>
              <Form.Select
                onChange={(e) =>
                  setFilter({ ...filter, betType: e.target.value })
                }
                value={filter.betType}
                aria-label="Default select example"
              >
                <option value="Exchange">Cricket</option>
                <option value="FancyBet">Tennis</option>
                <option value="Sportsbook">Soccer</option>
                <option value="casino">Casino</option>{" "}
              </Form.Select>
            </Col>
            <Col lg={3} sm={3}>
              <Form.Select
                onChange={(e) =>
                  setFilter({ ...filter, betType: e.target.value })
                }
                value={filter.betType}
                aria-label="Default select example"
              >
                <option value="Exchange">UnSettle</option>
                <option value="FancyBet">Settle</option>
                <option value="Sportsbook">Void</option>
              </Form.Select>
            </Col>
            <Col lg={3} sm={3}>
              <Form.Control
                type="date"
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    fromPeriod: e.target.value,
                    filterByDay: "",
                  })
                }
                max={new Date().toISOString().split("T")[0]}
                value={filter.fromPeriod}
              />
            </Col>
            <Col lg={3} sm={3}>
              <Form.Control
                type="date"
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    toPeriod: e.target.value,
                    filterByDay: "",
                  })
                }
                min={
                  filter?.fromPeriod
                    ? new Date(filter?.fromPeriod).toISOString().split("T")[0]
                    : new Date()
                }
                max={new Date().toISOString().split("T")[0]}
                value={filter.toPeriod}
              />
            </Col>
            <Col lg={3} sm={3} className="d-flex align-items-end">
              <Button onClick={() => getData()} className="theme_dark_btn">
                Get History
              </Button>
            </Col>
          </Row>

          <div className="w-100">
            <div className="account-table-header">Bet History</div>

            <div className="responsive table-color">
              {filter?.betType === "casino" ? (
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">Bet ID</th>
                      <th scope="col"> PL ID</th>
                      <th scope="col">Market</th>
                      {/* <th scope="col">Selection</th> */}
                      {/* <th scope="col">Type</th> */}
                      <th scope="col">Bet Placed</th>
                      {/* <th scope="col">Odds Req.</th> */}
                      <th scope="col">Stake</th>
                      {/* <th scope="col">Avg. odds Matched</th> */}
                      <th scope="col">Profit / Loss</th>
                    </tr>
                  </thead>
                  {data && data?.length > 0 ? (
                    data?.map((item, index) => {
                      // const resultTotalStake = item?.bets_list.reduce((a, v) => {
                      //   a = parseInt(a) + parseInt(v.amount);
                      //   return a;
                      // }, 0);

                      return (
                        <>
                          <tr
                            id="summary0"
                            style={{ display: "table-row" }}
                            key={index + 1}
                          >
                            <td>
                              {" "}
                              {/* <i
                              id={"icon_" + item?.platformTxId}
                              class="fas fa-plus-square"
                              onClick={(e) => showDetail(e, item?.platformTxId)}
                            ></i>{" "} */}
                              {item?.platformTxId}
                            </td>
                            <td>{item?.clientName}</td>
                            <td id="title" className="align-L">
                              Casino
                              <span className="angle_unicode">▸</span>
                              <strong> {item?.casinoName}</strong>
                              <span className="angle_unicode">▸</span>
                              {item?.gameCode}
                            </td>
                            {/* <td>null</td> */}
                            {/* <td>back</td> */}
                            <td id="settledDate">
                              {" "}
                              {helpers.dateFormat(item.timeInserted)}
                            </td>
                            {/* <td>0.00</td> */}
                            <td>{item?.betAmount}</td>
                            {/* <td>0.00</td> */}
                            <td>
                              {Math.sign(item?.playerPL) === -1 ? (
                                <span className="text-danger">
                                  ({helpers.currencyFormat(item?.playerPL)})
                                </span>
                              ) : (
                                <span className="text-success">
                                  (
                                  {helpers.currencyFormat(
                                    Math.abs(item?.playerPL)
                                  )}
                                  )
                                </span>
                              )}
                            </td>
                          </tr>

                          <tr className="expand" id={item?.platformTxId}>
                            <td colSpan="7" className="expand_wrap">
                              <table className="table-commission">
                                <tbody>
                                  <tr>
                                    <th>Bet Taken</th>
                                    <th>Odds Req.</th>
                                    <th>Stake</th>
                                    <th>Liability</th>
                                    <th>Odds Matched</th>
                                    <th>Action</th>
                                  </tr>
                                  <tr
                                    id="txRow0"
                                    style={{ display: "table-row" }}
                                    className="even"
                                  >
                                    <td id="betID">
                                      {" "}
                                      {helpers.dateFormat(item.timeInserted)}
                                    </td>
                                    <td>0.00</td>
                                    <td>{item?.betAmount}</td>
                                    <td>
                                      {item?.loseAmount
                                        ? item?.loseAmount
                                        : "-"}
                                    </td>
                                    <td>0.00</td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={12}>
                        <span>You have no bets in this time period.</span>
                      </td>
                    </tr>
                  )}
                </Table>
              ) : (
                <Table className="table_caption_style">
                  <thead>
                    <tr>
                      {headerData.map((res) => {
                        return (
                          <th
                            scope="col"
                            className={res.class}
                            width={res.widht}
                          >
                            {res.title}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {data && data?.length > 0 ? (
                      data?.map((item) => {
                        return (
                          <tr>
                            <td className="text-start" width="30%">
                              {item?.matchName}
                              {filter.betType === "sportsbook" ||
                              filter.betType === "fancy"
                                ? " > " + item?.fancyName
                                : ""}
                            </td>
                            <td className="text-end">
                              {obj?.betCheckObj[item?.eventType]}
                            </td>
                            <td className="text-end">
                              {" "}
                              {filter.betType === "fancy"
                                ? item?.betRun + "/" + item?.bhav
                                : filter.betType === "sportsbook"
                                ? item?.runnerName
                                : item?.teamName}
                            </td>
                            <td className="text-end">
                              {" "}
                              <span
                                className={
                                  item?.betType == "lay" || item?.type == "No"
                                    ? "lay-bg"
                                    : item?.betType == "back" ||
                                      item?.type == "Yes"
                                    ? "back-bg"
                                    : ""
                                }
                              >
                                {item?.betType || item?.type}
                              </span>
                            </td>
                            <td className="text-end">
                              {" "}
                              {item?.matchBetId || item?.sessionBetId}
                            </td>
                            <td className="text-end">
                              {" "}
                              {item?.amount || "N/A"}
                            </td>
                            <td className="text-end"> {item?.bhav || "N/A"}</td>
                            <td className="text-end"> {item?.bhav || "N/A"}</td>
                            <td className="text-end">
                              {" "}
                              {item?.bhav || "N/A"}{" "}
                            </td>

                            {filter.betType == "betfair" ||
                            filter.betType == "Bookmaker" ||
                            filter.betType == "toss" ||
                            filter.betType == "tie" ? (
                              <td className="text-end">
                                {(item?.teamSelectionWin == item?.selectionId &&
                                  item?.betType == "back") ||
                                (item?.teamSelectionWin != item?.selectionId &&
                                  item?.betType == "lay") ? (
                                  <span className="text-success">
                                    {item?.profitAmount}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    -({item?.loseAmount})
                                  </span>
                                )}
                              </td>
                            ) : filter.betType === "sportsbook" ? (
                              <td>
                                {item?.teamSelectionWin &&
                                item?.teamSelectionWin
                                  .split(",")
                                  .includes(item?.fancySelectionId) ? (
                                  <span className="text-success">
                                    {item?.profitAmount}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    -({item?.loseAmount})
                                  </span>
                                )}
                              </td>
                            ) : (
                              <td className="text-end">
                                {item?.type == "No" ? (
                                  item?.decisionRun < item?.betRun ? (
                                    <span className="text-success">
                                      {item?.profitAmount}
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      -({item?.loseAmount})
                                    </span>
                                  )
                                ) : item?.decisionRun >= item?.betRun ? (
                                  <span className="text-success">
                                    {item?.profitAmount}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    -({item?.loseAmount})
                                  </span>
                                )}
                              </td>
                            )}

                            <td>
                              {" "}
                              {moment(item?.timeInserted).format(
                                "YYYY-MM-DD, h:mm:ss"
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="12" className="text-start">
                          <span>You have no bets in this time period.</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
              <div className="bottom-pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" >"
                  forcePage={viewpage}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={10}
                  pageCount={pageCount}
                  previousLabel="< "
                  renderOnZeroPageCount={null}
                  activeClassName="p-1"
                  activeLinkClassName="pagintion-li"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BetHistoryNew;
