import React from "react";
import RenderBetSlip from "./RenderBetSlip";
import { FiInfo } from "react-icons/fi";
import { Table } from "react-bootstrap";

export const Bookmaker = ({
  bookmakerSelections,
  renderBookmarkBack,
  bookmakerBackOdds,
  bookmakerLayOdds,
  renderBookmarkLay,
  details,
  betSlipObject,
  onCancel,
  handelAcceptAnyOddsCheck,
  handelSetPrice,
  handelPlace,
  betLoader,
  amountRange,
}) => {
  return (
    <div className="special-bet-table">
      <div className="space-bet">
        <div className="top-wrapper-match bg-white mt-2">
          <div className="match-odd-scroller">
            <div className="match-top-wrapper d-flex align-items-center">
              <div className="odd-sec">
                <strong>
                  Bookmaker <FiInfo style={{ marginLeft: "5px" }} size={17} />
                </strong>
              </div>
              <div className="live-match">
                <span>Matched</span>
                <strong>USD 0</strong>
              </div>
            </div>
          </div>
        </div>
        <Table className="bookmaker-tabel">
          <tbody>
            <tr className="bet-all">
              <td colSpan="3" width="60%"></td>
              <td width="8%">
                <strong className="text-center d-block">Back</strong>
              </td>
              <td width="8%">
                {" "}
                <strong className="text-center d-block">Lay</strong>
              </td>
              <td colSpan="2"></td>
            </tr>
            {bookmakerSelections?.map((bookmarkSelection, index) => {
              return (
                <>
                  <tr className="bet-all new-bet" key={index}>
                    <td className="predict" width="40%">
                      {bookmarkSelection && (
                        <strong className="mb-0 pt-2 d-block">
                          {bookmarkSelection?.runnerName}
                          <div style={{ display: "flex" }}>
                            <span
                              style={
                                bookmarkSelection.position <= 0
                                  ? { color: "red", marginRight: "5px" }
                                  : {
                                      color: "green",
                                      marginRight: "5px",
                                    }
                              }
                            >
                              {Math.abs(bookmarkSelection.position) != 0 &&
                                !isNaN(
                                  Math.abs(bookmarkSelection.position)
                                ) && (
                                  <>
                                    {parseFloat(
                                      Math.abs(bookmarkSelection.position)
                                    ).toFixed(2)}
                                  </>
                                )}
                            </span>
                            <span
                              style={
                                bookmarkSelection.newPosition <= 0
                                  ? { color: "red" }
                                  : { color: "green" }
                              }
                            >
                              {Math.abs(bookmarkSelection.newPosition) != 0 &&
                                !isNaN(
                                  Math.abs(bookmarkSelection.newPosition)
                                ) && (
                                  <>
                                    <i className="fa fa-arrow-right"></i>
                                    &nbsp;&nbsp;
                                    {parseFloat(
                                      Math.abs(bookmarkSelection.newPosition)
                                    ).toFixed(2)}
                                  </>
                                )}
                            </span>
                          </div>
                        </strong>
                      )}
                    </td>
                    {renderBookmarkBack(bookmarkSelection)}
                    {renderBookmarkLay(bookmarkSelection)}
                    <>
                      {details?.sport_setting?.status == "inActive" ? (
                        <td
                          className="hide-data-table ps-0 suspend-bookmaker"
                          style={{ cursor: "not-allowed" }}
                        >
                          <table className="w-100 text-center">
                            <tbody>
                              <tr>
                                <td className="pe-xxl-5 pe-3">Suspend</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      ) : bookmakerBackOdds[0]?.ms != 1 ||
                        bookmakerLayOdds[0]?.ms != 1 ? (
                        <td
                          className="hide-data-table ps-0 suspend-bookmaker"
                          style={{ cursor: "not-allowed" }}
                        >
                          <table className="w-100 text-center">
                            <tbody>
                              <tr>
                                <td className="pe-xxl-5 pe-3">
                                  {bookmakerBackOdds[0]?.ms == 9 ||
                                  bookmakerLayOdds[0]?.ms == 9
                                    ? "Ball Running"
                                    : bookmakerBackOdds[0]?.ms == 2 ||
                                      bookmakerLayOdds[0]?.ms == 2
                                    ? "In Active"
                                    : bookmakerBackOdds[0]?.ms == 3 ||
                                      bookmakerLayOdds[0]?.ms == 3
                                    ? "Suspended"
                                    : bookmakerBackOdds[0]?.ms == 4 ||
                                      bookmakerLayOdds[0]?.ms == 4
                                    ? "Closed"
                                    : "Suspended"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      ) : (
                        ""
                      )}
                    </>
                  </tr>
                  {bookmarkSelection.selectionID ==
                    betSlipObject.selectionId && (
                    <RenderBetSlip
                      data={betSlipObject}
                      onCancel={onCancel}
                      type={"bookmaker"}
                      handelAcceptAnyOddsCheck={handelAcceptAnyOddsCheck}
                      handelSetPrice={handelSetPrice}
                      handelPlace={handelPlace}
                      details={details}
                      betLoader={betLoader}
                    />
                  )}
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
