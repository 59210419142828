import React, { useState, useEffect, useContext } from "react";
import {
  Tabs,
  Tab,
  Accordion,
  OverlayTrigger,
  Form,
  Row,
  Col,
  Container,
  Popover,
  Button,
  Table,
} from "react-bootstrap";
import Rightbar from "../components/Rightbar";
import { apiGet } from "../utils/apiFetch";
import helpers from "../utils/helpers";
import apiPath from "../utils/apiPath";
import { useForm } from "react-hook-form";
import { isEmpty, startCase } from "lodash";
import AuthContext from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import OddsListing from "../components/OddsListing";
import { Link } from "react-router-dom";
import moment from "moment";
import io from "socket.io-client";
import Layout from "./Layout";
import InplayInner from "./InplayInner";

const Inplay = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});
  const [isLoader, setLoader] = useState(false);
  const [checkedList, setCheckedList] = useState(["all", "1", "2", "4"]);
  const [getInPlaySports, setInPlaySports] = useState([]);
  const [getAllSports, setAllSports] = useState([]);
  const [centralizedIds, setCentralizedIds] = useState("");
  let { handelAddMultiMarket, runApi } = useContext(BetSlipContext);
  let {
    user,
    inPlayScore,
    loginUserDetailData,
    setInplaySocket,
    inplaySocket,
  } = useContext(AuthContext);
  const setCheckBoxData = (msgId) => {
    if (msgId === "all") {
      if (checkedList.length === 4) {
        setCheckedList([]);
      } else {
        setCheckedList(["all", "1", "2", "4"]);
      }
    } else {
      const newCheckedList = [...checkedList];
      const index = newCheckedList.indexOf(msgId);
      if (index === -1) {
        newCheckedList.push(msgId);
      } else {
        newCheckedList.splice(index, 1);
      }
      setCheckedList(newCheckedList);
    }
  };

  const onSubmit = async (request) => {
    setLoader(true);
    if (isEmpty(checkedList)) {
      setLoader(false);
      document.body.click();
    } else {
      let temp = checkedList ? `?keyword=${checkedList}` : "";
      const { status, data: response_users } = await apiGet(
        apiPath.userInPlaySport + temp
      );
      if (status === 200) {
        if (response_users.success) {
          setLoader(false);
          reset();
          document.body.click();
        } else {
          setLoader(false);
          document.body.click();
        }
      }
    }
  };

  const fetchAllSport = async () => {
    let temp = user?.user?._id ? `?userId=${user?.user?._id}` : "";
    const { status, data: response_users } = await apiGet(
      apiPath.userInPlaySport + temp
    );
    if (status === 200) {
      if (response_users.success) {
        setAllSports(response_users.results);
        setInPlaySports(response_users.results.inPlay);
        const marketIds = response_users.results.inPlay.map(
          (r) => r.centralizedId
        );
        if (marketIds && marketIds.length) {
          setCentralizedIds(marketIds);
        }
        if (response_users?.results?.inPlay?.length > 0) {
          getOdds(
            response_users?.results?.inPlay?.map((res) => {
              return res?.marketId;
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (inPlayScore?.length > 0) {
      setInPlaySports((current) => {
        return current.map((res) => {
          let temp = inPlayScore.find((item) => item?.eventId == res?.eventId);
          return {
            ...res,
            score: temp?.eventId == res?.eventId ? temp?.score : "",
            timeElapsed: temp?.eventId == res?.eventId ? temp?.timeElapsed : "",
          };
        });
      });
    }
  }, [inPlayScore]);

  useEffect(() => {
    fetchAllSport();
  }, []);

  useEffect(() => {
    fetchAllSport();
  }, [runApi]);

  const getOdds = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setInPlaySports((current) =>
            current.map((obj) => {
              let newObj = response_users?.results?.find((res) => {
                return res?.mi == obj?.marketId;
              });
              if (obj?.marketId == newObj?.mi) {
                return { ...obj, odds: newObj };
              }
              return obj;
            })
          );
        }
      }
    }
  };

  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const getSocket = () => {
    if (checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      if (!isEmpty(inplaySocket)) {
        inplaySocket.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${process.env.REACT_APP_API_BASE_URL2}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${process.env.REACT_APP_API_BASE_URL2}?user_id=${randomId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      setInplaySocket(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(true);
    } else {
      setCheckRefresh(false);
    }
  });

  const popover = (
    <Popover id="popover-basic" className="filter_popover">
      <Popover.Body>
        <Container>
          <Form className="filter_menu" onSubmit={handleSubmit(onSubmit)}>
            <Row className="gx-0">
              <Col md={6}>
                <div>
                  <Form.Group className="mb-0" controlId="formBasicCheckbox">
                    <Form.Check
                      label="All"
                      checked={
                        !isEmpty(checkedList) && checkedList.includes("all")
                      }
                      value="all"
                      onClick={() => setCheckBoxData("all")}
                    />
                  </Form.Group>
                </div>

                <div>
                  <Form.Group className="mb-0" controlId="formBasicCheckbox2">
                    <Form.Check
                      label="Tennis"
                      checked={
                        !isEmpty(checkedList) && checkedList.includes("2")
                      }
                      value="2"
                      onClick={() => setCheckBoxData("2")}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <Form.Group className="mb-0" controlId="formBasicCheckbox4">
                    <Form.Check
                      label="Soccer"
                      checked={
                        !isEmpty(checkedList) && checkedList.includes("1")
                      }
                      value="1"
                      onClick={() => setCheckBoxData("1")}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="mb-0" controlId="formBasicCheckbox5">
                    <Form.Check
                      label="Cricket"
                      checked={
                        !isEmpty(checkedList) && checkedList.includes("4")
                      }
                      value="4"
                      onClick={() => setCheckBoxData("4")}
                    />
                  </Form.Group>
                </div>
              </Col>

              <ul className="btn-wrap">
                <li className="col-send">
                  <Button type="submit" className="btn-send m-0">
                    {isLoader ? "Loading..." : "Save"}
                  </Button>
                </li>
                <li className="close_btn me-4">
                  <Button className="btn" onClick={() => document.body.click()}>
                    Cancel{" "}
                  </Button>
                </li>
              </ul>
            </Row>
          </Form>
        </Container>
      </Popover.Body>
    </Popover>
  );
  return (
    <Layout>
      <div className="theme_tabs">
        <Tabs defaultActiveKey="inplay">
          <Tab eventKey="inplay" title="Inplay">
            <InplayInner
              getInPlaySports={getAllSports?.inPlay}
              loginUserDetailData={loginUserDetailData}
              handelAddMultiMarket={handelAddMultiMarket}
              score={score}
            />
          </Tab>

          <Tab eventKey="today" title="Today">
            <InplayInner
              getInPlaySports={getAllSports?.toDay}
              loginUserDetailData={loginUserDetailData}
              handelAddMultiMarket={handelAddMultiMarket}
              score={score}
            />
          </Tab>

          <Tab eventKey="tomorrow" title="Tomorrow">
            <InplayInner
              getInPlaySports={getAllSports?.tomorrow}
              loginUserDetailData={loginUserDetailData}
              handelAddMultiMarket={handelAddMultiMarket}
              score={score}
            />
          </Tab>
        </Tabs>
      </div>
    </Layout>
  );
};

export default Inplay;
let USDollar = new Intl.NumberFormat("en-US", {
  currency: "USD",
});
