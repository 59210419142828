import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { startCase, isEmpty, lowerCase } from "lodash";
import { NavDropdown } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { FaLongArrowAltRight } from "react-icons/fa";
import { BsArrowDownSquare, BsArrowRightSquare } from "react-icons/bs";
const MatchSidebar = (props) => {
  const showToggle = () => {
    document.getElementById("body-parent").classList.toggle("sidenav");
  };

  const params = useParams();
  var eventId = params?.eventId || "";
  const [sportType, setSportType] = useState("");
  const { user } = useContext(AuthContext);
  const [showLogin, setShowLogin] = useState(false);
  const [tournamentData, setTournamentData] = useState([]);
  const [getSeriesId, setSeriesId] = useState("");
  const [matches, setMatches] = useState([]);
  const [getSeriesName, setSeriesName] = useState("");
  const [matchesWithId, setMatchesWithId] = useState("");
  const [getMatchName, setMatchName] = useState("");
  const [getAllSports, setAllSports] = useState([]);
  const [accordianToogle, setAccordianToogle] = useState("bets");
  const navigate = useNavigate();
  const [getMatchId, setMatchId] = useState(
    params.eventId ? params.eventId : ""
  );
  const [getMarketId, setMarketId] = useState(
    params.marketId ? params.marketId : ""
  );
  const getTournamentData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.seriesList + "?gameType=" + sportType
    );
    if (status === 200) {
      if (response_users.success) {
        setTournamentData(response_users.results);
      }
    }
  };
  const getMatches = async (id) => {
    id && setSeriesId(id);
    const { status, data: response_users } = await apiGet(
      apiPath.matchList + "?gameType=" + sportType + "&seriesId=" + id
    );
    if (status === 200) {
      if (response_users.success) {
        setMatches(response_users.results);
      }
    }
  };
  const handelAll = async () => {
    const { status, data: response_users } = await apiGet(apiPath.sportsList);
    if (status === 200) {
      if (response_users.success) {
        setAllSports(response_users.results);
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(sportType)) {
      getTournamentData();
    }
  }, [sportType]);
  return (
    <>
      <div className="match-sidebar">
        <ul className="mb-0">
          <div>
            <h6>Sports</h6>
            {tournamentData?.length > 0 ? (
              tournamentData.map((item, index) => {
                return (
                  <>
                    <li
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      key={index + 1}
                      onClick={() => {
                        if (
                          getSeriesId == item.seriesId &&
                          matches?.length > 0
                        ) {
                          setMatches([]);
                          setSeriesName("");
                          setSeriesId("");
                        } else {
                          setSeriesName(item.seriesName);
                          getMatches(item.seriesId);
                        }
                      }}
                    >
                      <Link to="#">{item.seriesName}</Link>
                      {getSeriesId == item.seriesId && matches?.length > 0 ? (
                        <BsArrowDownSquare color="#83AE17" size={18} />
                      ) : (
                        <BsArrowRightSquare color="#83AE17" size={18} />
                      )}
                    </li>
                    {getSeriesId == item.seriesId && matches?.length > 0 && (
                      <div>
                        {matches &&
                          matches.map((item, index) => {
                            return (
                              <li
                                style={{ paddingLeft: "24px" }}
                                key={index + 1}
                                onClick={() => {
                                  setMatches([]);
                                  setSeriesName("");
                                  setSeriesId("");
                                  navigate(
                                    `/markets/${sportType}/${item?.eventId}/${item?.marketId}`
                                  );
                                }}
                              >
                                <FaLongArrowAltRight />{" "}
                                <Link to="#">{item.eventName}</Link>
                              </li>
                            );
                          })}
                      </div>
                    )}
                  </>
                );
              })
            ) : (
              <>
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() => handelAll()}
                >
                  <Link to="#" onClick={() => setSportType("cricket")}>
                    Cricket
                  </Link>{" "}
                  <BsArrowRightSquare color="#83AE17" size={18} />
                </li>{" "}
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() => handelAll()}
                >
                  <Link to="#" onClick={() => setSportType("soccer")}>
                    Soccer
                  </Link>{" "}
                  <BsArrowRightSquare color="#83AE17" size={18} />
                </li>{" "}
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() => setSportType("tennis")}
                >
                  <Link to="#">Tennis</Link>{" "}
                  <BsArrowRightSquare color="#83AE17" size={18} />
                </li>{" "}
              </>
            )}
          </div>

          {/* {isEmpty(AllSports) ? (
            <div>
              <h6>Sports</h6>
              <li onClick={() => handelAll()}>
                <Link to="#">All Sports</Link>{" "}
              </li>
              <li
                className={!seriesName ? "active" : ""}
                onClick={() => handelBackFunc()}
              >
                <Link to="#">{startCase(matchType)}</Link>{" "}
              </li>
              {seriesName ? (
                matchName ? (
                  <div>
                    <li
                      onClick={() => {
                        setSeriesName(seriesName);
                        handelClickFunc(getSeriesId);
                      }}
                    >
                      <Link to="#">{seriesName}</Link>{" "}
                    </li>
                    <li className="active">
                      <Link to="#">{matchName}</Link>{" "}
                    </li>
                    <li
                      className=""
                      onClick={() => handelClickOddDetails(matchId, marketId)}
                    >
                      <Link to="#">
                        <img
                          className="icon-no_play"
                          src="../assets/images/transparent.gif"
                        />
                        Match Odds
                      </Link>{" "}
                    </li>
                  </div>
                ) : (
                  <li className="active">
                    <Link to="#">{seriesName}</Link>{" "}
                  </li>
                )
              ) : (
                <li className="menu-group-title">
                  <Link to="#">Common</Link>{" "}
                </li>
              )}

              {getSeriesId ? (
                <div>
                  {matchesWithId &&
                    matchesWithId.map((item, index) => {
                      return (
                        <li
                          key={index + 1}
                          onClick={() => {
                            setSeriesName(seriesName);
                            setMatchName(item.eventName);
                            setMatchId(item.eventId);
                            setMarketId(item.marketId);
                          }}
                        >
                          <Link to="#">{item.eventName}</Link>
                        </li>
                      );
                    })}
                </div>
              ) : (
                <div>
                  {tournamentData &&
                    tournamentData.map((item, index) => {
                      return (
                        <li
                          key={index + 1}
                          onClick={() => {
                            setSeriesName(item.seriesName);
                            handelClickFunc(item.seriesId);
                          }}
                        >
                          {" "}
                          <Link to="#">{item.seriesName}</Link>
                        </li>
                      );
                    })}
                </div>
              )}
            </div>
          ) : (
            <div>
              <h6>Sports</h6>
              {AllSports &&
                AllSports.map((item, index) => {
                  return (
                    <li
                      key={index + 1}
                      onClick={() => handelSportFunc(item.seriesId)}
                    >
                      {" "}
                      <Link
                        to={"/" + lowerCase(item.name)}
                        onClick={() => setSeriesName(item.seriesName)}
                      >
                        {item.name}
                      </Link>
                    </li>
                  );
                })}
            </div>
          )} */}
        </ul>
      </div>
    </>
  );
};

export default MatchSidebar;
