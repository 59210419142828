import React from 'react'
import Layout from './Layout'

const Lottery = () => {
  return (
    <Layout>
    <div className="col-center games_center_block">
      <div className="game_banner">
        <img
          src="../assets/images/kv_tennis.jpg"
          className="w-100"
          alt="No_Image"
        />
      </div>

      <div className="game_table_main_wrapper">
        <div className="game_list_filter_shape">
          <h4 style={{ fontWeight: "600" }}>Highlights</h4>
        </div>
<div></div>
      </div>
    </div>
  </Layout>
  )
}

export default Lottery