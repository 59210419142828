import React, { useState, useEffect, Fragment, useContext } from "react";
import { Accordion } from "react-bootstrap";
import _, { filter, pick } from "lodash";
import MatchSidebar from "../components/MatchSidebar";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import transparentGif from "../../src/assets/images/transparent.gif";
import moment from "moment/moment";
import { toast } from "wc-toast";
import AuthContext from "../context/AuthContext";
import Login from "../components/Login";
import helpers from "../utils/helpers";
import Loader from "../components/Loader";
import LoaderGif from "../assets/gif/loader.gif";
import BetSlipContext from "../context/BetSlipContext";
import loading40 from "../../src/assets/images/loading40.gif";
import io from "socket.io-client";
import Layout from "./Layout";
const isInputNumber = helpers.isInputNumber;
const MultiMarkets = () => {
  const showToggle = () => {
    document.getElementById("body-parent").classList.toggle("showbet");
  };
  let { activeOneClick, activeOneClickAmount, handelAddMultiMarket, runApi } =
    useContext(BetSlipContext);
  const [isLoader, setLoader] = useState(false);
  const params = useParams();
  const location = useLocation();
  var eventId = params.eventId;
  let sportType = params.sportType;
  let { user, loginUserDetail, loginUserDetailData, stakeData } =
    useContext(AuthContext);
  useContext(AuthContext);
  useEffect(() => {
    if (user != null) {
      loginUserDetail();
    }
  }, [user]);
  const navigate = useNavigate();
  //********** ALL STATE ******************************//
  const [minAmountCon, setMinAmountCond] = useState(false);
  const [fancy, setFancy] = useState(false);
  const [selections, setSelections] = useState([]);
  const [getEventId, setEventId] = useState([]);
  const [matchCentralizedIds, setMatchCentralizedIds] = useState({});
  const [backBetSlipArray, setBetBackSlipArray] = useState([]);
  const [layBetSlipArray, setLayBackSlipArray] = useState([]);
  const [priceList, setPriceList] = useState([
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
    { id: 4, value: 200 },
    { id: 5, value: 1000 },
    { id: 6, value: 5000 },
  ]);
  useEffect(() => {
    if (stakeData.length) {
      setPriceList(stakeData);
    }
  }, [user, stakeData.length]);

  const [backBetSlipValue, setBackBetSlipValue] = useState("");
  const [layBetSlipValue, setLayBetSlipValue] = useState("");
  const [details, setDetails] = useState([]);
  const [allBetSlipData, setAllBetslipData] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [disableBetFairPlaceBetBtn, setDisableBetFairPlaceBetBtn] =
    useState(true);
  const [loaderPlaceBetfair, setLoaderPlaceBetFair] = useState(false);

  const [betFairLiability, setBetFairLiability] = useState(0);
  const [fancyList, setFancyList] = useState([]);

  useEffect(() => {
    getBetPosition(getEventId);
  }, [getEventId, !allBetSlipData.length]);
  const getMatchDetails = async () => {
    setLoader(true);
    const { status, data: response_users } = await apiGet(apiPath.multiMarket);
    if (status === 200) {
      if (response_users.success) {
        response_users?.results.map((data) => {
          data?.runners?.map((it) => {
            it.position = 0;
          });
        });

        response_users?.results.map((data) => {
          data.newRunners = JSON.parse(JSON.stringify(data.runners));
        });
        setDetails(response_users?.results);
        const marketIds = response_users.results.map((r) => r.centralizedId);

        if (marketIds && marketIds.length) {
          setMatchCentralizedIds(marketIds);
        }
        setLoader(false);
        var mainArr = [];
        const eventIds = response_users.results.map((r) =>
          mainArr.push({ eventId: r.eventId })
        );

        if (mainArr && mainArr.length) {
          setEventId(mainArr);
        }
        setLoader(false);
      }
    }
  };
  const getBetPosition = async (getEventId) => {
    let eventIds = {
      eventIds: getEventId,
    };

    const { status, data: response_users } = await apiPost(
      apiPath.betEventsBetPosition,
      eventIds
    );
    if (status === 200) {
      if (response_users.success) {
        if (details.length && response_users?.results.length) {
          response_users?.results.map((el) => {
            let findMatch = details.find((data) => data.eventId == el.eventId);
            if (details.some((rt) => rt.eventId == el.eventId)) {
              let arr = findMatch?.newRunners.map((item) => {
                let temp = el.betFair.map((rq) => {
                  if (item.SelectionId == rq.selectionId) {
                    item.position = rq.position;
                  }
                  return item;
                });
                return temp;
              });

              let temp = findMatch?.runners.map((item) => {
                let temp = el.betFair.map((rq) => {
                  if (item.SelectionId == rq.selectionId) {
                    item.position = rq.position;
                  }
                  return item;
                });
                return temp;
              });

              setDetails((prevState) => prevState);
            }
          });
        }
      }
    }
  };
  useEffect(() => {
    if (user != null) {
      getMatchDetails();
    }
  }, [user]);
  useEffect(() => {
    if (user) {
      getMatchDetails();
    }
  }, [runApi, user]);

  useEffect(() => {
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL1}?token=${
        user?.user?._id ? user?.user?._id : 112233
      }&userType=front`,
      {
        transports: ["websocket"],
      }
    );
    const listenBetfairOdds = (event) => {
      const socket_data = (event && event?.results) || [];
      // console.log('socket_data----------------------', socket_data, socket_data.eventId, params?.eventId, socket_data.ms)
      if (socket_data.length > 0) {
        for (const the_odd of socket_data) {
          setDetails((current) =>
            current.map((obj) => {
              //console.log("obj?.eventId, the_odd?.eventId", obj?.eventId, the_odd?.eventId);
              if (obj?.eventId && obj?.eventId == the_odd?.eventId) {
                const back_odds = the_odd?.rt.filter((rt) => rt.ib) || [];
                const lay_odds = the_odd?.rt.filter((rt) => !rt.ib) || [];
                return { ...obj, back_odds: back_odds, lay_odds: lay_odds };
              }
              return obj;
            })
          );
        }
      }
    };
    newSocket.on("listenBetFairOdds", listenBetfairOdds);
    return () => newSocket.close();
  }, [details]);
  //console.log("details", details);
  //***************** BET FAIR FUNCTIONS ***************************//

  useEffect(() => {
    setAllBetslipData([...backBetSlipArray, ...layBetSlipArray]);
    handelSetLiability([...backBetSlipArray, ...layBetSlipArray]);
    disablePlaceBetFair([...backBetSlipArray, ...layBetSlipArray]);
  }, [backBetSlipArray, backBetSlipValue, layBetSlipArray, layBetSlipValue]);

  const handelShowBetSlip = (
    filter_odds,
    selection,
    betType,
    runners,
    eventId,
    minAmount
  ) => {
    const filteredData = {
      ...filter_odds,
      price: priceList,
      selectionId: selection.SelectionId,
      teamName: selection.RunnerName,
      betType: betType,
      eventId: eventId,
      minAmount: minAmount ? minAmount : 10,
      runners: runners,
      activePrice: loginUserDetailData?.defaultStake,
    };

    if (filter_odds?.ib == true) {
      const matchedRi = backBetSlipArray.find((el) => el.ri == filter_odds.ri);
      if (matchedRi) {
        const index = backBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);

        backBetSlipArray.splice(index, 1, filteredData);
        setBetBackSlipArray((prevState) => [...prevState]);
      } else {
        setBetBackSlipArray([...backBetSlipArray, filteredData]);
      }
    } else {
      const matchedRi = layBetSlipArray.find((el) => el.ri == filter_odds.ri);
      if (matchedRi) {
        const index = layBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);
        layBetSlipArray.splice(index, 1, filteredData);
        setLayBackSlipArray((prevState) => [...prevState]);
      } else {
        setLayBackSlipArray([...layBetSlipArray, filteredData]);
      }
    }
    showToggle();
  };

  const handelBetFairPositions = (slipArr, index, eventId) => {
    let findMatch = details.find((el) => el.eventId == eventId);
    let newselection = findMatch?.newRunners?.map((data) => {
      let positionTemp = findMatch?.runners?.find(
        (el) => el.SelectionId == data.SelectionId
      )?.position;
      data.position = positionTemp ? positionTemp : 0;
      return data;
    });

    setDetails((prevState) => prevState);

    slipArr.map((data, ind) => {
      if (data?.activePrice >= 0) {
        if (data.ib) {
          let currentPosition = findMatch?.newRunners?.find(
            (rl) => rl.SelectionId == data.ri
          );
          let otherTeamPosition = findMatch?.newRunners?.find(
            (rl) => rl.SelectionId !== data.ri && rl.RunnerName != "The Draw"
          );

          let backProfit = (data.rt - 1) * data.activePrice;
          let backLoss = data.activePrice;
          if (currentPosition) {
            currentPosition.position = currentPosition.position + backProfit;
            otherTeamPosition.position = otherTeamPosition.position - backLoss;
            const result = findMatch?.newRunners?.map((data) => {
              if (data.SelectionId == currentPosition.SelectionId) {
                data.position = currentPosition.position
                  ? parseFloat(currentPosition.position.toFixed(2))
                  : 0;
              }
              if (data.SelectionId == otherTeamPosition.SelectionId) {
                data.position = otherTeamPosition.position
                  ? parseFloat(otherTeamPosition.position.toFixed(2))
                  : 0;
              }
              return data;
            });
            setDetails((prevState) => prevState);
            if (index == ind) {
              const temp = backBetSlipArray;
              temp?.map((item, idxx) => {
                if (index == idxx) {
                  item.profitLiability = parseInt(backProfit.toFixed(2));
                }
              });
              setBetBackSlipArray((prevState) => prevState);
            }
          }
        } else {
          let currentPosition = findMatch?.newRunners?.find(
            (rl) => rl.SelectionId == data.ri
          );
          let otherTeamPosition = findMatch?.newRunners?.find(
            (rl) => rl.SelectionId !== data.ri && rl.RunnerName != "The Draw"
          );

          let backProfit = data.activePrice;
          let backLoss = (data.rt - 1) * data.activePrice;
          if (currentPosition) {
            currentPosition.position = currentPosition.position - backLoss;
            otherTeamPosition.position =
              otherTeamPosition.position + backProfit;
            const result = findMatch?.newRunners?.map((data) => {
              if (data.SelectionId == currentPosition.SelectionId) {
                data.position = currentPosition.position
                  ? parseFloat(currentPosition.position.toFixed(2))
                  : 0;
              }
              if (data.SelectionId == otherTeamPosition.SelectionId) {
                data.position = otherTeamPosition.position
                  ? parseFloat(otherTeamPosition.position.toFixed(2))
                  : 0;
              }
              return data;
            });
            setBetBackSlipArray((prevState) => prevState);
            const temp = layBetSlipArray;
            temp?.map((item, idx) => {
              if (index == idx) {
                item.profitLiability = parseInt(backProfit.toFixed(2));
              }
            });
            setLayBackSlipArray((prevState) => prevState);
          }
        }
      }
    });
  };
  const handelSetLiability = (slipArr) => {
    var original = 0;
    let temp = details.map((rq) => {
      let arr = rq?.newRunners.map((item) => {
        if (item.position < 0) {
          original += item.position;
        }
      });

      setBetFairLiability(original);
    });
  };
  const handelSetPrice = (item, data) => {
    const filteredData = {
      ...data,
      price: priceList,
      activePrice: item?.value,
      selectionId: data?.selectionId,
      teamName: data?.teamName,
      betType: data?.betType,
      eventId: data.eventId,
      runners: data?.runners,
    };

    if (data?.ib == true) {
      const matchedRi = backBetSlipArray.find((el) => el.ri == data.ri);
      if (matchedRi) {
        const index = backBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);

        backBetSlipArray.splice(index, 1, filteredData);
        setBetBackSlipArray((prevState) => [...prevState]);
        handelBetFairPositions(
          [...backBetSlipArray, ...layBetSlipArray],
          index,
          data?.eventId
        );
      }
    } else {
      const matchedRi = layBetSlipArray.find((el) => el.ri == data.ri);
      if (matchedRi) {
        const index = layBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);

        layBetSlipArray.splice(index, 1, filteredData);
        setLayBackSlipArray((prevState) => [...prevState]);
        handelBetFairPositions(
          [...backBetSlipArray, ...layBetSlipArray],
          index,
          data?.eventId
        );
      }
    }
  };
  const handelChangeBackAll = (event, index, data) => {
    setBackBetSlipValue(+event.target.value);
    const temp = backBetSlipArray;
    temp[index].activePrice = event.target.value ? +event.target.value : null;
    setBetBackSlipArray((prevState) => temp);
    handelBetFairPositions(
      [...backBetSlipArray, ...layBetSlipArray],
      index,
      data?.eventId
    );
  };
  const handelChangeLayAll = (event, index, data) => {
    setLayBetSlipValue(+event.target.value);
    const temp = layBetSlipArray;
    temp[index].activePrice = event.target.value ? +event.target.value : null;
    setLayBackSlipArray((prevState) => temp);
    handelBetFairPositions(
      [...backBetSlipArray, ...layBetSlipArray],
      index,
      data?.eventId
    );
  };
  const handelRemoveBackBetSlip = (index, eventId) => {
    const arr = [...backBetSlipArray];
    arr.splice(index, 1);
    setBetBackSlipArray(arr);
    handelBetFairPositions([...arr, ...layBetSlipArray], index, eventId);
  };
  const handelRemoveLayBetSlip = (index, eventId) => {
    const arr = [...layBetSlipArray];
    arr.splice(index, 1);
    setLayBackSlipArray(arr);
    handelBetFairPositions([...backBetSlipArray, ...arr], index, eventId);
  };
  const [betLoader, setBetLoader] = useState(false);
  const [timeLeft, setTimeLeft] = useState(5);
  const handelPlaceBetfair = async (
    filter_Odds,
    selections,
    betType,
    eventId,
    marketId
  ) => {
    const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
    const arr = [...backBetSlipArray, ...layBetSlipArray];
    const Bet = arr?.map((it) => {
      return {
        selectionId: it?.selectionId,
        teamName: it?.teamName,
        bhav: it?.rt || null,
        amount: it?.activePrice,
        betType: it?.betType,
        eventId: it?.eventId,
        marketId: it?.marketId,
      };
    });
    if (user) {
      if (activeOneClick) {
        if (!activeOneClickAmount) {
          //toast.error("Please select one click bet amount");
          return false;
        }
      }
      setTimeLeft(5);
      setBetLoader(true);
      setLoaderPlaceBetFair(true);
      const result = activeOneClick
        ? {
            betPlaceTime: date,
            type: sportType,
            bet: [
              {
                selectionId: filter_Odds?.ri,
                teamName: selections?.runnerName,
                bhav: filter_Odds?.rt,
                amount: activeOneClickAmount,
                betType: betType,
                eventId: eventId,
                marketId: marketId,
              },
            ],
          }
        : {
            betPlaceTime: date,
            bet: Bet,
            type: "cricket",
          };
      const { data: response_users2 } = await apiPost(
        apiPath.beforeBetPlace,
        result
      );
      if (response_users2.success) {
        const { status, data: response_users } = await apiPost(
          apiPath.multiEventPlaceBet,
          result
        );
        if (status === 200) {
          if (response_users.success) {
            setLoaderPlaceBetFair(false);
            setBetLoader(false);
            toast.success(response_users.message);
            getBetPosition();
            setBetBackSlipArray([]);
            setLayBackSlipArray([]);
            setAllBetslipData([]);
          } else {
            setLoaderPlaceBetFair(false);
            setBetLoader(false);
            toast.error(response_users.message);
          }
        }
      } else {
        setLoaderPlaceBetFair(false);
        setBetLoader(false);
        toast.error(response_users2.message);
      }
    } else {
      setShowLogin(true);
    }
  };
  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);
  const disablePlaceBetFair = (Arr) => {
    if (Arr.some((el) => !el.activePrice)) {
      setDisableBetFairPlaceBetBtn(true);
      setMinAmountCond(false);
    } else if (Arr.some((el) => el.activePrice < el.minAmount)) {
      setMinAmountCond(true);
      setDisableBetFairPlaceBetBtn(true);
    } else {
      setMinAmountCond(false);
      setDisableBetFairPlaceBetBtn(false);
    }
  };
  const handelClearAllBet = () => {
    setBetBackSlipArray([]);
    setLayBackSlipArray([]);
  };

  //******************************* BET FAIR UI ****************************************************//

  //****** BET FAIR BACK DATA ******//
  const renderBackOdds = (
    eventId,
    selection,
    back_odds,
    runners,
    marketId,
    matchSetting,
    matchOdds,
    matchStatus,
    gameType
  ) => {
    if (matchSetting && matchSetting.length) {
      var minAmount = matchSetting.find(
        (el) => el.type == "betFaire"
      ).minAmount;
    }

    const the_odds = back_odds;
    // console.log("back_odds----------->", back_odds);
    // console.log("selection--------->", selection);
    const filter_odds = the_odds.filter(
      (todd) => todd.ri === selection.SelectionId
    );

    return (
      <>
        <td
          value={filter_odds[2]?.rt}
          id={"back_odds_" + filter_odds[2]?.rt}
          className={`back-3 bet-cursor mobile_hide ${
            document
              .getElementById("back_odds_" + filter_odds[2]?.rt)
              ?.getAttribute("value") != filter_odds[2]?.rt &&
            filter_odds[2]?.rt &&
            "spark"
          }`}
          onClick={() =>
            filter_odds[2]?.rt == 0 ||
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[2]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[2]?.rt
              ? null
              : activeOneClick
              ? handelPlaceBetfair(
                  filter_odds[2],
                  selection,
                  "back",
                  eventId,
                  marketId
                )
              : handelShowBetSlip(
                  filter_odds[2],
                  selection,
                  "back",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds[2]?.rt == 0 ||
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[2]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[2]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[2]?.rt || null}
            <span>{filter_odds[2]?.rt > 0 && filter_odds[2]?.bv}</span>
          </a>
        </td>
        <td
          value={filter_odds[1]?.rt}
          id={"back_odds_" + filter_odds[1]?.rt}
          className={`back-2 bet-cursor mobile_hide ${
            document
              .getElementById("back_odds_" + filter_odds[1]?.rt)
              ?.getAttribute("value") != filter_odds[1]?.rt &&
            filter_odds[1]?.rt &&
            "spark"
          }`}
          onClick={() =>
            filter_odds[1]?.rt == 0 ||
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[1]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[1]?.rt
              ? null
              : activeOneClick
              ? handelPlaceBetfair(
                  filter_odds[1],
                  selection,
                  "back",
                  eventId,
                  marketId
                )
              : handelShowBetSlip(
                  filter_odds[1],
                  selection,
                  "back",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds[1]?.rt == 0 ||
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[1]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[1]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[1]?.rt || null}
            <span>{filter_odds[1]?.rt > 0 && filter_odds[1]?.bv}</span>
          </a>
        </td>
        <td
          value={filter_odds[0]?.rt}
          id={"back_odds_" + filter_odds[0]?.rt}
          className={`back-1s bet-cursor ${
            document
              .getElementById("back_odds_" + filter_odds[0]?.rt)
              ?.getAttribute("value") != filter_odds[0]?.rt &&
            filter_odds[0]?.rt &&
            "spark"
          }`}
          onClick={() =>
            filter_odds[0]?.rt == 0 ||
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[0]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[0]?.rt
              ? null
              : activeOneClick
              ? handelPlaceBetfair(
                  filter_odds[0],
                  selection,
                  "back",
                  eventId,
                  marketId
                )
              : handelShowBetSlip(
                  filter_odds[0],
                  selection,
                  "back",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds[0]?.rt == 0 ||
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[0]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[0]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[0]?.rt || null}
            <span>{filter_odds[0]?.rt > 0 && filter_odds[0]?.bv}</span>
          </a>
        </td>
      </>
    );
  };
  const renderLayOdds = (
    eventId,
    selection,
    lay_odds,
    runners,
    marketId,
    matchSetting,
    matchOdds,
    matchStatus,
    gameType
  ) => {
    const the_odds = lay_odds;
    const filter_odds = the_odds.filter(
      (todd) => todd.ri === selection.SelectionId
    );
    // .sort((a, b) => {
    //   return a - b;
    // });

    if (matchSetting && matchSetting.length) {
      var minAmount = matchSetting.find(
        (el) => el.type == "betFaire"
      ).minAmount;
    }
    return (
      <>
        <td
          value={filter_odds[0]?.rt}
          id={"lay_odds_" + filter_odds[0]?.rt}
          className={`lay-1 bet-cursor ${
            document
              .getElementById("lay_odds_" + filter_odds[0]?.rt)
              ?.getAttribute("value") != filter_odds[0]?.rt &&
            filter_odds[0]?.rt &&
            "spark"
          }`}
          onClick={() =>
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[0]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[0]?.rt
              ? null
              : activeOneClick
              ? handelPlaceBetfair(
                  filter_odds[0],
                  selection,
                  "lay",
                  eventId,
                  marketId
                )
              : handelShowBetSlip(
                  filter_odds[0],
                  selection,
                  "lay",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[0]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[0]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[0]?.rt || null}
            <span>{filter_odds[0]?.bv}</span>
          </a>
        </td>
        <td
          value={filter_odds[1]?.rt}
          id={"lay_odds_" + filter_odds[1]?.rt}
          className={`lay-2 bet-cursor mobile_hide  ${
            document
              .getElementById("lay_odds_" + filter_odds[1]?.rt)
              ?.getAttribute("value") != filter_odds[1]?.rt &&
            filter_odds[1]?.rt &&
            "spark"
          }`}
          onClick={() =>
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[1]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[1]?.rt
              ? null
              : activeOneClick
              ? handelPlaceBetfair(
                  filter_odds[1],
                  selection,
                  "lay",
                  eventId,
                  marketId
                )
              : handelShowBetSlip(
                  filter_odds[1],
                  selection,
                  "lay",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[1]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[1]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[1]?.rt || null}
            <span>{filter_odds[1]?.bv}</span>
          </a>
        </td>
        <td
          value={filter_odds[2]?.rt}
          id={"lay_odds_" + filter_odds[2]?.rt}
          className={`lay-3 bet-cursor mobile_hide ${
            document
              .getElementById("lay_odds_" + filter_odds[2]?.rt)
              ?.getAttribute("value") != filter_odds[2]?.rt &&
            filter_odds[2]?.rt &&
            "spark"
          }`}
          onClick={() =>
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[2]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[2]?.rt
              ? null
              : activeOneClick
              ? handelPlaceBetfair(
                  filter_odds[2],
                  selection,
                  "lay",
                  eventId,
                  marketId
                )
              : handelShowBetSlip(
                  filter_odds[2],
                  selection,
                  "lay",
                  runners,
                  eventId,
                  minAmount
                )
          }
          style={
            filter_odds.length === 0 ||
            selection?.RunnerName == "The Draw" ||
            matchOdds == "off" ||
            matchStatus !== "in_play" ||
            matchSetting?.find((res) => res?.type === "betFaire")?.oddsLimit <=
              filter_odds[2]?.rt ||
            loginUserDetailData?.matchSetting?.find(
              (res) => res?.type === "betFaire" && res?.sportType === gameType
            )?.oddsLimit <= filter_odds[2]?.rt
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          <a>
            {filter_odds[2]?.rt || null}
            <span>{filter_odds[2]?.bv}</span>
          </a>
        </td>
      </>
    );
  };
  useEffect(() => {
    if (details.length > 0) {
      setLoader(false);
    }
  }, [details.length]);

  return (
    <Layout>
      <div className="col-center games_center_block game-wrapper-block">
        {isLoader ? (
          <span className="d-flex justify-content-center align-item-center mt-2 pb-3">
            <img src={LoaderGif} alt="No_Image" />
          </span>
        ) : details.length > 0 ? (
          <div className="match-odds-table game_table_main_wrapper">
            {details.length > 0 &&
              details.map((matchData, index) => {
                return (
                  <>
                    {" "}
                    <div className="space-bet mb-3">
                      <div className="special_bet mb-3">
                        <h3 className="mb-0 d-flex align-items-center">
                          <button
                            className="add-pin"
                            onClick={() =>
                              handelAddMultiMarket(matchData?.eventId)
                            }
                            title="Add to Multi Markets"
                          ></button>
                          <Link
                            to={`/markets/${matchData?.gameType}/${matchData?.eventId}/${matchData?.marketId}`}
                          >
                            {matchData?.runners[0]?.RunnerName} v{" "}
                            {matchData?.runners[1]?.RunnerName}
                            <p>Match Odds</p>
                          </Link>
                          <strong class="sportstrong"></strong>
                        </h3>

                        <dl className="fancy-info">
                          <button
                            className="multi_refresh"
                            onClick={() => getMatchDetails()}
                          ></button>
                        </dl>
                      </div>

                      <table className="bets game_table_inner">
                        <tbody>
                          <tr className="bet-all" key={index + 1}>
                            <td className="text-start">
                              {matchData?.runners?.length} selections
                            </td>
                            <td
                              id="backPercent"
                              className="refer-bet mobile_hide"
                              colSpan="2"
                            >
                              100.6%
                            </td>
                            <td className="back-all_td">
                              <a id="backAll" className="back-all">
                                <i className="backall-shape"></i>
                                <span>Back all</span>
                              </a>
                            </td>
                            <td className="lay-all_td">
                              <a id="layAll" className="lay-all">
                                <i className="layall-shape"></i>
                                <span>Lay all</span>
                              </a>
                            </td>
                            <td
                              id="layPercent"
                              className="refer-book mobile_hide"
                              colSpan="2"
                            >
                              99.9%
                            </td>
                          </tr>
                          {/* {console.log(" matchData?.newRunners matchData?.back_odds", matchData?.newRunners, matchData?.back_odds)} */}
                          {matchData?.back_odds &&
                            matchData?.newRunners.map((selection, index) => {
                              return (
                                <tr key={index + 1}>
                                  <th className="">
                                    <p>
                                      <a href="#">
                                        <img
                                          className="icon-predict"
                                          src="/images/transparent.gif"
                                        />
                                      </a>
                                      {selection?.RunnerName}
                                      <p
                                        style={
                                          selection.position <= 0
                                            ? { color: "red" }
                                            : { color: "green" }
                                        }
                                      >
                                        {Math.abs(selection.position) != 0 &&
                                          !isNaN(
                                            Math.abs(selection.position)
                                          ) && (
                                            <>
                                              <i className="fa fa-arrow-right"></i>
                                              &nbsp;&nbsp;
                                              {parseFloat(
                                                Math.abs(selection.position)
                                              ).toFixed(2)}
                                            </>
                                          )}
                                      </p>
                                    </p>
                                    <span id="" className="lose d-none">
                                      {" "}
                                      0.00
                                    </span>
                                    <span id="" className="win d-none"></span>
                                    <span id="" className="to-lose d-none">
                                      {" "}
                                      0.00
                                    </span>
                                  </th>
                                  {matchData?.back_odds?.length &&
                                    renderBackOdds(
                                      matchData.eventId,
                                      selection,
                                      matchData.back_odds,
                                      matchData.runners,
                                      matchData.marketId,
                                      matchData.matchSetting
                                        ? matchData.matchSetting
                                        : 10,
                                      matchData?.matchOdds,
                                      matchData?.status,
                                      matchData?.gameType
                                    )}
                                  {matchData?.lay_odds?.length &&
                                    renderLayOdds(
                                      matchData.eventId,
                                      selection,
                                      matchData.lay_odds,
                                      matchData.runners,
                                      matchData.marketId,
                                      matchData.matchSetting
                                        ? matchData.matchSetting
                                        : 10,
                                      matchData?.matchOdds,
                                      matchData?.status,
                                      matchData?.gameType
                                    )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              })}
          </div>
        ) : (
          <div className="no-multi">
            <h3>There are currently no followed multi markets.</h3>
            <p>Please add some markets from events.</p>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default MultiMarkets;
