import React from "react";
import { FiInfo } from "react-icons/fi";
import RenderBetSlip from "./RenderBetSlip";

const BetFair = ({
  selections,
  renderBackOdds,
  renderLayOdds,
  marketName,
  BackOdds,
  LayOdds,
  marketId,
  betFairMs,
  amountRangeBetFair,
  totalMatched,
  user,
  betSlipObject,
  onCancel,
  handelAcceptAnyOddsCheck,
  handelSetPrice,
  handelPlace,
  details,
  betLoader,
  amountRange,
  type
}) => {
  return (
    <>
      <div className="top-wrapper-match bg-white mt-2">
        <div className="match-odd-scroller">
          <div className="match-top-wrapper d-flex align-items-center">
            <div className="odd-sec">
              <strong>
                {marketName} <FiInfo style={{ marginLeft: "5px" }} size={17} />
              </strong>
            </div>
            <div className="live-match">
              <span>Matched</span>
              <strong>USD {totalMatched}</strong>
              {user !== null ? <h5>Live</h5> : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="match-odds-table game_table_main_wrapper" key="2">
        <table className="bets game_table_inner">
          <tbody>
            <tr className="bet-all" style={{ background: "white" }}>
              <td className="text-start"></td>
              <td className="px-0">
                <table>
                  <tr>
                    <td id="backPercent" className="refer-bet mobile_hide"></td>
                    <td className="back-all_td">
                      <a id="backAll" className="back-all">
                        <i className="backall-shape"></i>
                        <span>Back all</span>
                      </a>
                    </td>
                  </tr>
                </table>
              </td>
              <td className="px-0">
                <table>
                  <tr>
                    <td className="lay-all_td">
                      <a id="layAll" className="lay-all">
                        <i className="layall-shape"></i>
                        <span>Lay all</span>
                      </a>
                    </td>
                    <td
                      id="layPercent"
                      className="refer-book mobile_hide"
                      colSpan="2"
                    >
                      <div className="max">
                        <h6 className="mb-0">
                          Min / Max - {amountRange?.minAmount} -{" "}
                          {amountRange?.maxAmount}
                        </h6>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            {selections?.map((selection, index) => {
              let disabelBack =
                "lockBackBets" in selection ? selection?.lockBackBets : false;
              let disabelLay =
                "lockLayBets" in selection ? selection?.lockLayBets : false;
              return (
                <>
                  <tr key={index + 1} className="relative_row">
                    <th className="">
                      <p>
                        <a href="#">
                          <img
                            className="icon-predict"
                            src="/images/transparent.gif"
                          />
                        </a>
                        {selection?.RunnerName}
                        <div style={{ display: "flex" }}>
                          <span
                            style={
                              selection.position <= 0
                                ? { color: "red", marginRight: "5px" }
                                : { color: "green", marginRight: "5px" }
                            }
                          >
                            {Math.abs(selection.position) != 0 &&
                              !isNaN(Math.abs(selection.position)) && (
                                <>
                                  {parseFloat(
                                    Math.abs(selection.position)
                                  ).toFixed(2)}
                                </>
                              )}
                          </span>
                          <span
                            style={
                              selection.newPosition <= 0
                                ? { color: "red" }
                                : { color: "green" }
                            }
                          >
                            {Math.abs(selection.newPosition) != 0 &&
                              !isNaN(Math.abs(selection.newPosition)) && (
                                <>
                                  <i className="fa fa-arrow-right"></i>
                                  &nbsp;&nbsp;
                                  {parseFloat(
                                    Math.abs(selection.newPosition)
                                  ).toFixed(2)}
                                </>
                              )}
                          </span>
                        </div>
                      </p>
                    </th>
                    {renderBackOdds(
                      selection,
                      BackOdds,
                      marketName,
                      marketId,
                      disabelBack ? 3 : betFairMs
                    )}
                    {renderLayOdds(
                      selection,
                      LayOdds,
                      marketName,
                      marketId,
                      disabelLay || marketName == "To Win the Toss"
                        ? 3
                        : betFairMs
                    )}
                  </tr>
                  {selection.SelectionId == betSlipObject?.selectionId && (
                    <RenderBetSlip
                      data={betSlipObject}
                      onCancel={onCancel}
                      type={type == 'multi' ? 'multi' : "betFair"}
                      handelAcceptAnyOddsCheck={handelAcceptAnyOddsCheck}
                      handelSetPrice={handelSetPrice}
                      handelPlace={handelPlace}
                      details={details}
                      betLoader={betLoader}
                    />
                  )}{" "}
                </>
              );
            })}
          </tbody>
        </table>
      </div>{" "}
    </>
  );
};

export default BetFair;
