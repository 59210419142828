import React, { useState, useContext } from "react";
import Login from "../components/Login";
import MatchSidebar from "../components/MatchSidebar";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Container } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import LoginBeforeModel from "../components/LoginBeforeModel";
import OpenBets from "./MatchOdds/OpenBets";
import { isEmpty } from "lodash";
import obj from "../utils/helpers";
const Layout = ({ news, children }) => {
  const params = useParams();

  const { user, setBeforeModal, beforeModal, message } =
    useContext(AuthContext);
  const [accordianToogle, setAccordianToogle] = useState("bets");
  const [showLogin, setShowLogin] = useState(false);
  const [market, setMarket] = useState("");
  return (
    <div>
      <div className="full-wrap">
        <MatchSidebar />
        <div
          style={{
            overflowY: "scroll",
            height: "100%",
            width: "60%",
            padding: "6px",
          }}
        >
          {news && (
            <div className="d-flex justify-content-center">
              <section className="breadcum-sec" style={{ width: "97.5%" }}>
                {!isEmpty(user) && message.length > 0 && (
                  <div
                    className="bredcum-sec-main"
                    style={{ borderRadius: "0" }}
                  >
                    <h5 className="mb-0">
                      <i className="fa-solid fa-microphone"></i> News
                    </h5>
                    <marquee width="100%">
                      <div style={{ display: "flex" }}>
                        {message.map((res) => {
                          return (
                            <div style={{ marginRight: "20px" }}>
                              <span style={{ marginRight: "10px" }}>
                                {obj.msgDateFormat(res.msgDate)} -{" "}
                              </span>
                              <span>{res.message}</span>
                            </div>
                          );
                        })}
                      </div>
                    </marquee>
                  </div>
                )}
              </section>
            </div>
          )}

          {children}
        </div>

        {/* <button
          className="rightbar_btn d-block d-lg-none"
          onClick={() => showToggle()}
        >
          <i className="fa-solid fa-bars"></i>
        </button> */}
        <div className="col-right">
          <div className="theme_accordian">
            <Accordion
              defaultActiveKey={["bets", "myBets"]}
              onSelect={(e) => setAccordianToogle(e)}
              alwaysOpen
            >
              <Accordion.Item
                eventKey="myBets"
                className={user ? "login-accordion-item" : ""}
              >
                <Accordion.Header>Open Bets</Accordion.Header>
                <Accordion.Body>
                  <OpenBets market={market} setMarket={setMarket} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <LoginBeforeModel
        show={beforeModal}
        onHide={() => setBeforeModal(false)}
      />
    </div>
  );
};

export default Layout;
