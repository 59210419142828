import React, { useContext } from "react";
import helpers from "../../utils/helpers";
import { inRange, isEmpty } from "lodash";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
const RenderBetSlip = ({
  data,
  onCancel,
  type,
  handelAcceptAnyOddsCheck,
  handelSetPrice,
  handelPlace,
  details,
  betLoader,
}) => {
  const {user} = useContext(AuthContext)
  const navigate = useNavigate();
  const isInputNumber = helpers.isInputNumber;
  let condition =
    inRange(
      data?.activePrice,
      data?.rangeObject.minAmount,
      data?.rangeObject.maxAmount
    ) &&
    details?.sport_setting?.status == "active" &&
    data?.rt > 0;
  return (
    <tr>
      {" "}
      <td colSpan="7" className="quick-batting-wrapper p-0">
        <div
          className="d-flex align-items-center batting-flex py-2 px-3"
          style={
            data?.betType == "back"
              ? { backgroundColor: "#BEDDF4" }
              : data?.betType == "lay"
              ? { backgroundColor: "#F3DCE2" }
              : {}
          }
        >
          <div className="d-flex max-batting align-items-center justify-content-start">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                onChange={(e) => handelAcceptAnyOddsCheck(e)}
                checked={data?.acceptAny}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Accept Any Odds
              </label>
            </div>
          </div>

          <dd className="col-btn mx-3 ">
            <button
              className="bookmakerCancelBtn w-100 fw-5"
              onClick={() => onCancel(type)}
            >
              Cancel
            </button>
          </dd>

          <dd
            className="quick-bet-confirm d-flex justify-content-end align-item-center"
            style={{ height: "33px" }}
          >
            <strong className="d-flex align-items-center">{data?.rt}</strong>
          </dd>

          <dd className="up_down_rate mx-3 quick-input">
            <input
              type="text"
              value={data?.activePrice ? data?.activePrice : ""}
              onChange={(e) => handelSetPrice(e.target.value, data)}
              onKeyPress={(e) => {
                isInputNumber(e);
                if (e.target.value.length > 6) {
                  e.preventDefault();
                }
              }}
              max={1500000}
            />
          </dd>

          <dd
            className="col-send"
            style={
              !condition
                ? {
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            <button
              className="bookmaker-place-bet-btn btn"
              onClick={() => {
                if (!isEmpty(user)) {
                  handelPlace();
                } else {
                  navigate("/login");
                }
              }}
              disabled={betLoader ? true : !condition}
              style={
                !condition
                  ? {
                      color: "#fff",
                      background: "gray",
                    }
                  : {}
              }
            >
              Place Bets
              {betLoader && (
                <span style={{ marginLeft: "10px" }}>
                  <Loader
                    Style={{
                      borderTop: "7px solid #FFB80C",
                      borderBottom: "7px solid #FFB80C",
                      width: "10px",
                      height: "10px",
                    }}
                  />
                </span>
              )}
            </button>
          </dd>
        </div>
        <div
          className="preset_value p-0 m-0"
          style={
            data?.betType == "back"
              ? { backgroundColor: "#D4E8F8" }
              : data?.betType == "lay"
              ? { backgroundColor: "#FAEFF2" }
              : {}
          }
        >
          <ul>
            {data?.price?.map((money) => {
              return (
                <li key={money?.id}>
                  <button
                    className="price-button-pay-slip"
                    onClick={() => handelSetPrice(money?.value, data)}
                    style={
                      data?.activePrice === money?.value
                        ? {
                            background: "#444444",
                            color: "#f8d61c ",
                            cursor: "not-allowed",
                            padding: "6px 10px",
                            minWidth: "95px",
                            fontWeight: "300",
                          }
                        : {
                            padding: "4px 12px",
                            minWidth: "114px",
                            fontWeight: "300",
                          }
                    }
                    disabled={data?.activePrice === money.value}
                  >
                    {money?.value}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </td>
    </tr>
  );
};

export default RenderBetSlip;
