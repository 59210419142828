import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import AuthContext from "../context/AuthContext";
import moment from "moment";
import Layout from "./Layout";

const RollingCommission = () => {
  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  const [filter, setFilter] = useState({
    type: "4",
    betType: "betfair",
    status: "completed",
    fromPeriod: previousDate,
    toPeriod: currentDate,
    filterByDay: "",
    page: 1,
    limit: 100,
  });

  let { user } = useContext(AuthContext);
  const [getLogData, setLogData] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [search_params, setSearchParams] = useState({
    page: 1,
    limit: 100,
  });
  const myStatementData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.transactionLogs,
      search_params
    );
    if (status === 200) {
      if (response_users.success) {
        setLogData(response_users.results);
      }
    }
  };

  const handlePageClick = (event) => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        page: event.selected + 1,
      };
    });
  };

  // useEffect(() => {
  //   setPageCount(getLogData?.totalPages || []);
  // }, [getLogData]);

  // useEffect(() => {
  //   myStatementData();
  // }, [search_params]);

  const onSubmit = () => {};
  return (
    <Layout>
      <div className="d-flex justify-content-center align-items-center pt-3">
        <div
          className="account-table d-flex flex-column align-items-center"
          style={{ width: "98%" }}
        >
          <Row
            style={{
              background: "#e0e6e6",
              padding: "10px 10px 0",
              border: "1px solid #000501",
              borderRadius: "5px",
              marginBottom: "10px",
              paddingBottom: "10px",
            }}
            className="container"
          >
            <Col lg={3} sm={3}>
              <Form.Select
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
                value={filter.status}
                aria-label="Default select example"
              >
                <option value="">Data Source</option>

                <option value="completed">Live Data</option>
                <option value="suspend">Backup Data</option>
                <option value="voided">Old Data</option>
              </Form.Select>
            </Col>
            <Col lg={3} sm={3}>
              <Form.Select
                onChange={(e) =>
                  setFilter({ ...filter, gameType: e.target.value })
                }
                value={filter.gameType}
                aria-label="Default select example"
              >
                <option value="">Select Sport</option>

                <option value="Cricket">Cricket</option>
                <option value="Tennis">Tennis</option>
                <option value="Soccer">Soccer</option>
              </Form.Select>
            </Col>
            <Col lg={3} sm={3}>
              <Form.Control
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    fromPeriod: e.target.value,
                    filterByDay: "",
                  })
                }
                max={new Date().toISOString().split("T")[0]}
                value={filter.fromPeriod}
                type="date"
              />
            </Col>
            <Col lg={3} sm={3}>
              <Form.Control
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    toPeriod: e.target.value,
                    filterByDay: "",
                  })
                }
                min={
                  filter?.fromPeriod
                    ? new Date(filter?.fromPeriod).toISOString().split("T")[0]
                    : new Date()
                }
                disabled={filter.fromPeriod ? false : true}
                max={new Date().toISOString().split("T")[0]}
                value={filter.toPeriod}
                type="date"
              />
            </Col>
            <Col lg={2} sm={2} className="d-flex align-items-end">
              <Button className="theme_dark_btn">Get Statement</Button>
            </Col>
          </Row>
          <div className="w-100">
            <div className="account-table-header">Rolling Commission</div>
            <div className="responsive table-color">
              <Table>
                <thead>
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Total Stake</th>
                    <th scope="col">Commission</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={3}>No records found</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>{" "}
        </div>
      </div>
    </Layout>
  );
};

export default RollingCommission;
