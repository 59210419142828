import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  Nav,
  Navbar,
  Dropdown,
  Button,
  Modal,
} from "react-bootstrap";
import { FaSearchPlus } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { NavLink, useLocation, useParams } from "react-router-dom";
import io from "socket.io-client";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import LiveSvg from "../assets/images/home/live.svg";
import settingSvg from "../assets/images/home/setting.svg";
import logoSkyexchange from "../assets/images/home/dream444.png";
import { toast } from "wc-toast";
import { apiPost, apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import OneClickBetPopup from "./OneClickBetPopup";
import OneClickBet from "./OneClickBet";
import BetSlipContext from "../context/BetSlipContext";
import obj from "../utils/helpers";
import loginSVG from "../assets/images/home/login.svg";
import { FaArrowRightToBracket } from "react-icons/fa6";
const Header = () => {
  let {
    loginUser,
    logoutUser,
    user,
    setUserCoins,
    user_coins,
    loginUserDetail,
    handleShow,
    casinoShow,
    setBeforeModalData,
    setBeforeModal,
  } = useContext(AuthContext);
  const location = useLocation();
  let { setActiveOneClick, setOneClickBetAmount } = useContext(BetSlipContext);
  const [dropDownShow, setDropDownShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const parmas = useParams();
  const [oneClickBetshow, setOneClickBetShow] = useState(false);
  const handleOneClickBetClose = () => setOneClickBetShow(false);
  const handleOneClickBetShow = () => setOneClickBetShow(true);
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    setValue: setValue1,
    reset: reset1,
  } = useForm({});
  const [editShown, setEditShown] = useState(false);
  const [okShown, setOkShown] = useState(true);
  const [defaultStake, setDefaultStake] = useState(0);
  const [oddsChecked, setOddsChecked] = useState(false);
  const [fancyBetChecked, setFancyBetChecked] = useState(false);
  const [sportsBookChecked, setSportsBookChecked] = useState(false);
  const [binaryChecked, setBinaryChecked] = useState(false);
  const [mainStake, setMainStake] = useState([]);
  const [stakedata, setStakeData] = useState([]);
  const [stakeOneClickdata, setEditOneClickStake] = useState([]);
  const [activeOneClickBet, setActiveOneClickBet] = useState(false);
  const [defaultOneClickStake, setDefaultOneClickStake] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchEventList, setSearchEventList] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [isRefereshLoader, setRefereshLoader] = useState(false);
  useEffect(() => {
    setActiveOneClick(activeOneClickBet);
    setOneClickBetAmount(defaultOneClickStake);
  }, [activeOneClickBet, defaultOneClickStake]);
  const oneClickBet = async (activeOneClickBet) => {
    setActiveOneClickBet(activeOneClickBet);
    handleOneClickBetShow();

    try {
      const { status, data: response_users } = await apiPost(
        apiPath.userProfileUpdate,
        { isOneClickBetStake: activeOneClickBet }
      );
      if (status === 200) {
        if (response_users.success) {
          myProfile();
          //toast.success(response_users.message);
        } else {
          toast.error(response_users.message);
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
  const myProfile = async () => {
    const { status, data: response_users } = await apiGet(apiPath.userProfile);
    if (status === 200) {
      if (response_users.success) {
        setValue1("defaultStake", response_users?.results?.defaultStake);
        let stakeD = response_users?.results?.editStake;
        stakeD.sort((a, b) => a - b);
        setStakeData(stakeD);
        setDefaultOneClickStake(
          response_users?.results?.defaultOneClickBetStake
        );
        setActiveOneClickBet(response_users?.results?.isOneClickBetStake);
        setEditOneClickStake(response_users?.results?.editOneClickBetStake);

        var commonstake = response_users?.results?.stake.filter((i) =>
          stakeD.includes(i)
        );

        setMainStake(commonstake);
        setOddsChecked(response_users?.results?.odds);
        setSportsBookChecked(response_users?.results?.sportsBook);
        setFancyBetChecked(response_users?.results?.fancyBet);
        setBinaryChecked(response_users?.results?.binary);
      }
    }
  };
  const handleStake = (e, i) => {
    let values = [...stakedata];
    values[i] = parseInt(e.target.value);
    setStakeData(values);
  };
  const handleEditClick = (event) => {
    setEditShown(true);
    setOkShown(false);
  };
  const handleOkClick = async (event) => {
    try {
      const { status, data: response_users } = await apiPost(
        apiPath.userEditStake,
        { editStake: stakedata }
      );
      if (status === 200) {
        if (response_users.success) {
        } else {
        }
      }
    } catch (err) {}

    stakedata.sort((a, b) => a - b);
    setOkShown(true);
    setEditShown(false);
  };
  const recallCasinoAmount = async (event) => {
    setLoader(true);
    try {
      const { status, data: response_users } = await apiPost(
        apiPath.withdrawCasinoAmount,
        { amount: user_coins?.casinoCoins }
      );
      if (status === 200) {
        if (response_users.status) {
          if (response_users.data.status === "0000") {
            setLoader(false);
            toast.success(response_users.message);
          } else {
            setLoader(false);
            toast.error(response_users.data?.desc);
          }
        } else {
          setLoader(false);
          toast.error(response_users.message);
        }
      }
    } catch (err) {
      console.log("1111111");
      setLoader(false);
      toast.error(err.response.message);
    }
  };
  const setStakeShow = (value) => {
    let main = [...mainStake];
    const index = main.indexOf(value);
    if (mainStake.length < 6) {
      if (index === -1) {
        main.push(value);
      } else {
        main.splice(index, 1);
      }
    } else {
      if (index === -1) {
        main.sort((a, b) => a - b);
        main.shift();
        main.push(value);
      } else {
        main.splice(index, 1);
      }
    }

    setMainStake(main);
  };
  const onSubmit1 = async (request) => {
    setLoader(true);

    if (!isEmpty(mainStake) && mainStake.length > 4) {
      try {
        const { status, data: response_users } = await apiPost(
          apiPath.userProfileUpdate,
          {
            defaultStake: request.defaultStake,
            fancyBet: fancyBetChecked,
            sportsBook: sportsBookChecked,
            binary: binaryChecked,
            odds: oddsChecked,
            stake: mainStake,
          }
        );
        if (status === 200) {
          if (response_users.success) {
            setLoader(false);
            setDefaultStake(response_users?.results?.defaultStake);
            setMainStake(response_users?.results?.stake);
            setOddsChecked(response_users?.results?.odds);
            setSportsBookChecked(response_users?.results?.sportsBook);
            setFancyBetChecked(response_users?.results?.fancyBet);
            setBinaryChecked(response_users?.results?.binary);
            toast.success(response_users.message);
            setDropDownShow(false);
            loginUserDetail();
          } else {
            setLoader(false);
            toast.error(response_users.message);
          }
        }
        setLoader(false);
      } catch (err) {
        setLoader(false);
        toast.error(err.response.data.message);
      }
    } else {
      setLoader(false);
      toast.error("please select at least 5 stake");
    }
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });

  const onSubmit = (data) => {
    setBeforeModal(true);
    setBeforeModalData(data);
  };

  useEffect(() => {
    if (user != null) {
      myProfile();
    }
  }, [user]);

  const getCoins = async () => {
    if (!isEmpty(user)) {
      setRefereshLoader(true);
      const { status, data } = await apiGet(apiPath.refreshAmount);
      if (status === 200) {
        if (data.success) {
          setRefereshLoader(false);
          setUserCoins({
            exposure: data?.results?.exposure,
            totalCoins: data?.results?.totalCoins,
          });
        } else {
          setRefereshLoader(false);
        }
      } else {
        setRefereshLoader(false);
      }
    }
  };

  const [message, setMessage] = useState([]);
  const messageList = async () => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    hostname = hostname || "bazz247.com";

    const { status, data: response_users } = await apiGet(
      apiPath.messageList + "?domain=" + hostname
    );
    if (status === 200) {
      if (response_users.success) {
        setMessage(response_users.results);
      }
    }
  };
  const handelSearchData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.searchEvent + "?keyword=" + searchKeyword
    );
    if (status === 200) {
      if (response_users.success) {
        setSearchEventList(response_users.results);
      }
    }
  };
  const handelSearch = (keyword) => {
    setSearchKeyword(keyword);
  };
  const clearSearch = () => {
    setSearchKeyword("");
  };
  useEffect(() => {
    handelSearchData();
  }, [searchKeyword]);

  useEffect(() => {
    if (user != null) {
      messageList();
    }
    if (!isEmpty(user)) {
      getCoins();
    }
  }, [user]);
  const [casionData, setCasinoData] = useState(0);
  const mainBalanceClick = async () => {
    const { status, data: response_users } = await apiPost(apiPath.awcBalance);
    if (status === 200) {
      if (!isEmpty(response_users.data.balance)) {
        setCasinoData(response_users.data.balance);
      }
    }
  };
  return (
    <div>
      <div className="top">
        <div className="home_header">
          <Container fluid>
            <Row className="align-items-center">
              <Col md={5} className="col-6">
                <div className="d-flex align-items-center">
                  <Link to="/" className="px-md-2 home_logo">
                    <img
                      src={logoSkyexchange}
                      alt="No_Image"
                      style={{ height: `67px` }}
                    />
                  </Link>
                </div>
              </Col>
              <Col md={7} className="col-6">
                {!isEmpty(user) ? (
                  <div className="d-block after_login">
                    <div className="d-flex align-items-start justify-content-end">
                      {isRefereshLoader ? (
                        <p class="loading-bar" id="menuRefreshLoading">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </p>
                      ) : (
                        <div className="login_right align-items-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic1"
                              className="d-lg-flex"
                            >
                              <div
                                onClick={mainBalanceClick}
                                className="price_label"
                              >
                                <span>Main Balance</span>
                                <h5 id="betCredit" className="fw-bold">
                                  {user_coins?.totalCoins?.toFixed(2)}
                                </h5>
                              </div>
                              <div
                                onClick={mainBalanceClick}
                                className="price_label"
                              >
                                <span>Exposure</span>
                                <h5 id="totalExposure" className="fw-bold">
                                  {user_coins?.exposure?.toFixed(2)}
                                </h5>
                              </div>
                              <div
                                onClick={mainBalanceClick}
                                className="head_count"
                              >
                                +<span id="vendorQuantity">4</span>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div className="main_balance mb-2">
                                <div className="wallet-detail-group">
                                  <div className="wallet-detail-content">
                                    <h5>Main Balance</h5>
                                    <div className="wallet-balance-num mb-2">
                                      <span
                                        className="badge-currency"
                                        id="currency"
                                      >
                                        PTH
                                      </span>
                                      <span id="mainBalance">
                                        {" "}
                                        {user_coins?.totalCoins?.toFixed(2)}
                                      </span>
                                    </div>
                                    <div className="wallet-exposure">
                                      Exposure{" "}
                                      <span id="mainExposure">
                                        {" "}
                                        {user_coins?.exposure?.toFixed(2)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="main_balance">
                                <div className="wallet-detail-group">
                                  <div className="wallet-detail-content">
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <h5 className="m-0">Casino Balance</h5>
                                        <div className="wallet-balance-num mb-2">
                                          <span
                                            className="badge-currency"
                                            id="currency"
                                          >
                                            PTH
                                          </span>
                                          <span id="mainBalance">
                                            {casionData
                                              ? casionData
                                              : user_coins?.casinoCoins?.toFixed(
                                                  2
                                                )}
                                          </span>
                                        </div>
                                      </div>
                                      <Link
                                        to="#"
                                        className="ms-auto recall"
                                        onClick={(e) => {
                                          if (
                                            casionData
                                              ? casionData
                                              : user_coins?.casinoCoins > 0
                                          ) {
                                            recallCasinoAmount(e);
                                          }
                                        }}
                                      >
                                        Recall
                                      </Link>
                                    </div>
                                  </div>

                                  <div className="wallet-detail-content">
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <h5 className="m-0">BPoker Balance</h5>
                                        <div className="wallet-balance-num mb-2">
                                          <span
                                            className="badge-currency"
                                            id="currency"
                                          >
                                            PTH
                                          </span>
                                          <span id="mainBalance">0 Points</span>
                                        </div>
                                      </div>
                                      <a href="#" className="ms-auto recall">
                                        Recall
                                      </a>
                                    </div>
                                  </div>

                                  <div className="wallet-detail-content">
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <h5 className="m-0">SABA Balance</h5>
                                        <div className="wallet-balance-num mb-2">
                                          <span
                                            className="badge-currency"
                                            id="currency"
                                          >
                                            PTH
                                          </span>
                                          <span id="mainBalance">0</span>
                                        </div>
                                      </div>
                                      <a href="#" className="ms-auto recall">
                                        Recall
                                      </a>
                                    </div>
                                  </div>

                                  <div className="wallet-detail-content">
                                    <div className="d-flex align-items-center">
                                      <div>
                                        <h5 className="m-0">
                                          Sky Trader Balance
                                        </h5>
                                        <div className="wallet-balance-num mb-2">
                                          <span
                                            className="badge-currency"
                                            id="currency"
                                          >
                                            PTH
                                          </span>
                                          <span id="mainBalance">0</span>
                                        </div>
                                      </div>
                                      <a href="#" className="ms-auto recall">
                                        Recall
                                      </a>
                                    </div>
                                  </div>
                                  <div className="wallet-detail-content">
                                    <div className="d-flex align-items-center">
                                      <a href="#" className="ms-auto recall">
                                        Recall All
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="close_btn mt-2 ">
                                  <a href="#" className="text-center text-dark">
                                    Close
                                  </a>
                                </div>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}

                      <a
                        style={{
                          color: "white",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        className="reload_link"
                      >
                        <i
                          className="fas fa-redo-alt"
                          onClick={() => getCoins()}
                        ></i>
                      </a>
                      {location?.pathname?.split("/")[2] !== "mobile" && (
                        <div className="my_account  ms-md-3 ms-1">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="m-0"
                            >
                              <i className="fas fa-user"></i>
                              <span className="d-none d-sm-inline-block ps-2">
                                {" "}
                                My Account
                              </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <ul id="account_pop" className="account-wrap">
                                <li>
                                  <h4 className="d-flex justify-content-between">
                                    {user && user?.user?.username
                                      ? user?.user?.username
                                      : ""}
                                    <span className="gmt" title="Time Zone">
                                      GMT+5:30
                                    </span>
                                  </h4>
                                </li>

                                <li>
                                  <Link to={"/my-profile"}> My Profile </Link>
                                </li>
                                <li>
                                  <Link
                                    to={
                                      '/rolling-commission'
                                    }
                                  >
                                    Rolling Commission
                                  </Link>
                                </li>
                                <li>
                                  {" "}
                                  <Link
                                    to={
                                      location?.pathname?.split("/")[2] ===
                                      "mobile"
                                        ? `/account-statement/mobile`
                                        : "/account-statement"
                                    }
                                  >
                                    Account Statement
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    to={
                                      location?.pathname?.split("/")[2] ===
                                      "mobile"
                                        ? "/betshistory/mobile"
                                        : "/betshistory"
                                    }
                                  >
                                    Bets History
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={
                                      location?.pathname?.split("/")[2] ===
                                      "mobile"
                                        ? "/profit_loss/mobile"
                                        : "/profit_loss"
                                    }
                                  >
                                    Profit &amp; Loss
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={
                                      location?.pathname?.split("/")[2] ===
                                      "mobile"
                                        ? "/activity-logs/mobile"
                                        : "/activity-logs"
                                    }
                                    target="_blank"
                                  >
                                    Activity Log
                                  </Link>
                                </li>
                                <li className="logout">
                                  <a id="logout" onClick={logoutUser}>
                                    LOGOUT{" "}
                                    <img
                                      className="icon-login ms-1"
                                      src="/assets/images/home/login.svg"
                                    />
                                  </a>
                                </li>
                              </ul>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="before_login">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <ul className="login-wrap d-none d-md-flex list-unstyled mb-0 justify-content-md-end ms-md-0 ms-5 ps-4">
                        <li className="user">
                          <FaSearchPlus
                            color="white"
                            size={20}
                            style={{ marginRight: "10px" }}
                          />
                          <Form.Group>
                            <Form.Control
                              type="text"
                              placeholder="Username"
                              className={errors.username ? "is-invalid" : ""}
                              {...register("username", {
                                required: "Please enter Username",
                              })}
                            />
                            {errors.username && errors.username.message && (
                              <label className="invalid-feedback text-left">
                                {errors.username.message}
                              </label>
                            )}
                          </Form.Group>
                        </li>
                        <li>
                          <Form.Group>
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              className={errors.password ? "is-invalid" : ""}
                              {...register("password", {
                                required: "Please enter password",
                                validate: (value) => {
                                  if (value === "") {
                                    return true;
                                  }
                                  // var paswd =
                                  //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})";
                                  // if (value.match(paswd)) {
                                  //   return true;
                                  // } else {
                                  //   return "Password formate invalid.";
                                  // }
                                },
                              })}
                            />
                            {errors.password && errors.password.message && (
                              <label className="invalid-feedback text-left">
                                {errors.password.message}
                              </label>
                            )}
                          </Form.Group>
                        </li>

                        <li>
                          <Button
                            type="submit"
                            className="btn-login"
                            id="loginBtn"
                          >
                            Login
                            <FaArrowRightToBracket
                              color="black"
                              style={{ marginLeft: "5px" }}
                            />
                          </Button>
                        </li>
                        <li>
                          {/* <Link to="/signup" className="btn-signup">
                            Sign up
                          </Link> */}
                        </li>
                      </ul>

                      <ul className="login-wrap list-unstyled m-0 p-0 justify-content-end d-flex d-md-none">
                        <li>
                          <a
                            href="#"
                            id="loginBtn"
                            className="btn-login"
                            onClick={handleShow}
                          >
                            Login
                            <FaArrowRightToBracket color="black" />
                          </a>
                        </li>
                        <li>
                          {/* <a href="#" className="btn-signup">
                            Sign up
                          </a> */}
                        </li>
                      </ul>
                    </Form>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="menu-wrap">
        <Container fluid>
          <Row>
            <Col lg={7} sm={3} className="col-2">
              <Navbar expand="lg" expanded={expanded}>
                <Navbar.Toggle
                  aria-controls="navbarScroll"
                  onClick={() => setExpanded(expanded ? false : "expanded")}
                />
                <Navbar.Collapse id="navbarScroll">
                  <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                    <NavLink
                      to={"/"}
                      className="my-0"
                      end
                      onClick={() =>
                        location?.pathname?.split("/")[2] === "mobile"
                          ? window.close()
                          : setExpanded(false)
                      }
                    >
                      Home
                    </NavLink>
                    <NavLink
                      to={"/inplay"}
                      className="my-0"
                      onClick={() =>
                        location?.pathname?.split("/")[2] === "mobile"
                          ? window.close()
                          : setExpanded(false)
                      }
                    >
                      In-Play
                    </NavLink>
                    <NavLink
                      to={"/multi-markets"}
                      className="my-0"
                      end
                      onClick={() =>
                        location?.pathname?.split("/")[2] === "mobile"
                          ? window.close()
                          : setExpanded(false)
                      }
                    >
                      Multi Markets
                    </NavLink>
                    <NavLink
                      to={"/cricket"}
                      className="my-0"
                      onClick={() =>
                        location?.pathname?.split("/")[2] === "mobile"
                          ? window.close()
                          : setExpanded(false)
                      }
                    >
                      {/* <span id="tagLive" className="tag-live">
                        <strong>
                          <img src={LiveSvg} alt="No_Image" />
                        </strong>
                        {inplayCountData.cricketInplayCount}
                      </span> */}
                      Cricket
                    </NavLink>
                    <NavLink
                      to={"/soccer"}
                      className="my-0"
                      onClick={() =>
                        location?.pathname?.split("/")[2] === "mobile"
                          ? window.close()
                          : setExpanded(false)
                      }
                    >
                      {/* <span id="tagLive" className="tag-live">
                        <strong>
                          <img src={LiveSvg} alt="No_Image" />
                        </strong>
                        {inplayCountData.soccerInplayCount}
                      </span> */}
                      Soccer
                    </NavLink>
                    <NavLink
                      to={"/tennis"}
                      className="my-0"
                      onClick={() =>
                        location?.pathname?.split("/")[2] === "mobile"
                          ? window.close()
                          : setExpanded(false)
                      }
                    >
                      {/* <span id="tagLive" className="tag-live">
                        <strong>
                          <img src={LiveSvg} alt="No_Image" />
                        </strong>
                        {inplayCountData.tennisInplayCount}
                      </span> */}
                      Tennis
                    </NavLink>

                    <NavLink to={"/"} className="my-0">
                      Hore Racing
                    </NavLink>
                    <NavLink to={"/lottery"} className="my-0">
                      Lottery
                    </NavLink>
                    <NavLink to={"/"} className="my-0">
                      Live Casino
                    </NavLink>
                    <NavLink to={"/"} className="my-0">
                      Virtaul Sports
                    </NavLink>
                    <NavLink to={"/"} className="my-0">
                      Tips & Previews
                    </NavLink>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>

            {!isEmpty(user) && (
              <Col
                lg={5}
                sm={9}
                className="col-10"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Dropdown className="setting border-0 " show={dropDownShow}>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{ fontWeight: "600" }}
                    onClick={() => {
                      if (isEmpty(user)) {
                        handleShow();
                      } else {
                        setDropDownShow(!dropDownShow);
                      }
                    }}
                  >
                    Setting
                    <img src={settingSvg} className="ms-1" alt="No_Image" />
                  </Dropdown.Toggle>

                  {!isEmpty(user) && (
                    <Dropdown.Menu>
                      <Form onSubmit={handleSubmit1(onSubmit1)}>
                        <div className="stake-set">
                          <div className="col-defult d-flex align-items-center">
                            <label htmlFor="defult_stake" className="me-2">
                              <strong>Default stake</strong>
                            </label>
                            <Form.Control
                              type="number"
                              className="stake-input"
                              {...register1("defaultStake")}
                            />
                          </div>
                        </div>

                        <div className="stake-set">
                          <label htmlFor="defult_stake" className="me-2 mb-1">
                            <strong>Stake</strong>
                          </label>
                          <div className="edit_wrap mb-1">
                            {okShown && (
                              <div
                                className="d-flex justify-content-between"
                                id="stakeSet"
                              >
                                <div className="wrapper-p d-flex flex-wrap">
                                  {stakedata &&
                                    stakedata.map((item, index) => {
                                      return (
                                        <div key={index + 1}>
                                          <a
                                            id={"coin_" + index + 1}
                                            className={
                                              mainStake.includes(item)
                                                ? "btn select"
                                                : "btn"
                                            }
                                            onClick={(event) =>
                                              setStakeShow(item)
                                            }
                                          >
                                            {item}
                                          </a>
                                        </div>
                                      );
                                    })}
                                </div>
                                <div className="col-edit border-0 d-block">
                                  <a
                                    href="#"
                                    id="edit"
                                    className="btn-edit"
                                    onClick={handleEditClick}
                                  >
                                    Edit
                                    <i className="ms-1 fas fa-pencil"></i>
                                  </a>
                                </div>
                              </div>
                            )}

                            {editShown && (
                              <div
                                className="d-flex justify-content-between"
                                id="stakeSet"
                              >
                                <div className="wrapper-p d-flex flex-wrap">
                                  {stakedata &&
                                    stakedata.map((item, index) => {
                                      return (
                                        <div key={index + 1}>
                                          <Form.Control
                                            type="text"
                                            name="stake[]"
                                            value={item}
                                            className="stake_edit-input"
                                            onChange={(e) =>
                                              handleStake(e, index)
                                            }
                                          />
                                        </div>
                                      );
                                    })}
                                </div>
                                <div className="col-edit border-0 d-block">
                                  <a
                                    href="#"
                                    id="edit"
                                    className="btn-edit ok-edit"
                                    onClick={handleOkClick}
                                  >
                                    Ok
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="odds-set">
                          <h6>Odds</h6>
                          <div className="d-flex align-items-inherit">
                            {/* <input id="enableSparkCheck" type="checkbox" /> */}
                            <Form.Check
                              onChange={(e) => setOddsChecked(!oddsChecked)}
                              checked={oddsChecked}
                            />

                            <label htmlFor="enableSparkCheck">
                              Highlight when odds change
                            </label>
                          </div>
                        </div>
                        <div className="odds-set">
                          <h6>FancyBet</h6>
                          <div className="d-flex align-items-inherit">
                            {/* <input id="enableSparkCheck" type="checkbox" /> */}
                            <Form.Check
                              onChange={(e) =>
                                setFancyBetChecked(!fancyBetChecked)
                              }
                              checked={fancyBetChecked}
                            />
                            <label htmlFor="enableSparkCheck">
                              Accept Any Odds
                            </label>
                          </div>
                        </div>
                        <div className="odds-set">
                          <h6>SportsBook</h6>
                          <div className="d-flex align-items-inherit">
                            {/* <input id="enableSparkCheck" type="checkbox" /> */}
                            <Form.Check
                              onChange={(e) =>
                                setSportsBookChecked(!sportsBookChecked)
                              }
                              checked={sportsBookChecked}
                            />
                            <label htmlFor="enableSparkCheck">
                              Accept Any Odds
                            </label>
                          </div>
                        </div>
                        <div className="odds-set">
                          <h6>Binary</h6>
                          <div className="d-flex align-items-inherit">
                            {/* <input id="enableSparkCheck" type="checkbox" /> */}
                            <Form.Check
                              onChange={(e) => setBinaryChecked(!binaryChecked)}
                              checked={binaryChecked}
                            />
                            <label htmlFor="enableSparkCheck">
                              Accept Any Price
                            </label>
                          </div>
                        </div>

                        <ul className="btn-wrap">
                          <li className="close_btn me-4">
                            <a
                              href="#"
                              id="closeSet"
                              className="btn"
                              disabled={editShown ? true : ""}
                              onClick={() => setDropDownShow(false)}
                            >
                              Cancel
                            </a>
                          </li>
                          <li className="col-send">
                            <Button
                              type="submit"
                              className="btn-send m-0"
                              disabled={editShown ? true : ""}
                            >
                              {isLoader ? "Loading..." : "Save"}
                            </Button>
                            {/* <a href="#" id="coinSave" className="btn-send m-0">
                            Save
                          </a> */}
                          </li>
                        </ul>
                      </Form>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </Col>
            )}
          </Row>
        </Container>
      </div>

      {!isEmpty(user) && activeOneClickBet && (
        <>
          <OneClickBetPopup
            show={oneClickBetshow}
            handleClose={handleOneClickBetClose}
            stakedata={stakeOneClickdata}
            defaultOneClickStake={defaultOneClickStake}
          />
          <OneClickBet
            stakedata={stakeOneClickdata}
            defaultOneClickStake={defaultOneClickStake}
            setDefaultOneClickStake={setDefaultOneClickStake}
          />
        </>
      )}
      {/* {location.pathname == "/" && (
        <section className="breadcum-sec">
          <Container>
            {user && message && message.length > 0 && (
              <div className="bredcum-sec-main">
                <h5 className="mb-0">
                  <i className="fa-solid fa-microphone"></i> News
                </h5>
                <marquee width="100%">
                  <div style={{ display: "flex" }}>
                    {message.map((res) => {
                      return (
                        <div style={{ marginRight: "20px" }}>
                          <span style={{ marginRight: "10px" }}>
                            {obj.msgDateFormat(res.msgDate)} -{" "}
                          </span>
                          <span>{res.message}</span>
                        </div>
                      );
                    })}
                  </div>
                </marquee>
              </div>
            )}
          </Container>
        </section>
      )} */}
    </div>
  );
};

export default Header;
