import React from "react";
import { Table } from "react-bootstrap";
const OpenBets = ({ market, setMarket }) => {
  return (
    <div className="matched-unmatched">
      <div className="matches_select">
        <select
          value={market}
          onChange={(e) => setMarket(e.target.value)}
          style={{ width: "100%" }}
        >
          <option value="odds">Select Market</option>
        </select>
      </div>
      {/* {obj[myBetsSelect]?.length > 0 && (
        <div>
          <div className="unmatch-match-sec">
            <span>Matched</span>
          </div>
          {obj[myBetsSelect]?.length > 0 && (
            <Table className="mb-0">
              <thead>
                <tr>
                  <th id="oddsHeader" className="col-bet">
                    {" "}
                    Back (Bet for)
                  </th>
                  <th id="oddsHeader" className="col-odd">
                    {" "}
                    Odds
                  </th>
                  <th id="runsHeader" className="col-odd">
                    {" "}
                    Stake
                  </th>
                  <th className="col-stake"> Profit</th>
                </tr>
              </thead>
              <tbody>
                {obj[myBetsSelect]?.map((res, index) => {
                  if (
                    myBetsSelect == "odds" ||
                    myBetsSelect == "bookmaker" ||
                    myBetsSelect == "sportsbook"
                  ) {
                    if (res.betType == "back") {
                      return (
                        <tr
                          key={index + 1}
                          style={{ background: "lightblue" }}
                          className="lay_bg_tuch"
                        >
                          <td className="match_oddds d-block">
                            <button
                              style={{
                                border: "none",
                                background: "#72bbef",
                                borderRadius: "5px",
                                marginRight: "8px",
                                padding: "3px 7px",
                                fontSize: "11px",
                              }}
                            >
                              Back
                            </button>
                            <strong style={{ fontSize: "11px" }}>
                              {res.teamName}
                            </strong>{" "}
                            <h5
                              className="d-block mt-1 ms-5"
                              style={{ fontSize: "11px" }}
                            >
                              <small>Match Odds</small>
                            </h5>
                          </td>
                          <td>{res.bhav}</td>
                          <td>{res.amount}</td>
                          <td>{res.profitAmount}</td>
                        </tr>
                      );
                    }
                  } else if (myBetsSelect == "fancyBet") {
                    if (res.type == "Yes") {
                      return (
                        <tr
                          style={{ background: "lightblue" }}
                          className="lay_bg_tuch"
                        >
                          <td className="match_oddds d-block">
                            <button
                              style={{
                                border: "none",
                                background: "#72bbef",
                                borderRadius: "5px",
                                marginRight: "8px",
                                padding: "3px 7px",
                                fontSize: "11px",
                              }}
                            >
                              Yes
                            </button>
                            <strong style={{ fontSize: "11px" }}>
                              {res.matchName}
                            </strong>{" "}
                            <h5
                              className="d-block mt-1 ms-5"
                              style={{ fontSize: "11px" }}
                            >
                              <small>Match Odds</small>
                            </h5>
                          </td>
                          <td>
                            {res.betRun}/{res.bhav}
                          </td>
                          <td>{res.amount}</td>
                          <td>{res.profitAmount}</td>
                        </tr>
                      );
                    }
                  }
                })}
              </tbody>
            </Table>
          )}

          <Table>
            <thead>
              <tr>
                <th id="oddsHeader" className="col-bet">
                  {" "}
                  Lay (Bet Againts)
                </th>
                <th id="oddsHeader" className="col-odd">
                  {" "}
                  Odds
                </th>
                <th id="runsHeader" className="col-odd">
                  {" "}
                  Stake
                </th>
                <th className="col-stake">Liability</th>
              </tr>
            </thead>
            <tbody>
              {obj[myBetsSelect]?.map((res, index) => {
                if (myBetsSelect == "odds" || myBetsSelect == "bookmaker") {
                  if (res.betType == "lay") {
                    return (
                      <tr key={index + 1} className="lay_bg_tuch">
                        <td className="match_oddds d-block">
                          <button
                            style={{
                              border: "none",
                              background: "#faa9ba",
                              borderRadius: "5px",
                              marginRight: "8px",
                              padding: "3px 7px",
                              fontSize: "11px",
                            }}
                          >
                            Lay
                          </button>
                          <strong style={{ fontSize: "11px" }}>
                            {res.teamName}
                          </strong>{" "}
                          <h5
                            className="d-block mt-1 ms-5"
                            style={{ fontSize: "11px" }}
                          >
                            <small>Match Odds</small>
                          </h5>
                        </td>
                        <td>{res.bhav}</td>
                        <td>{res.amount}</td>
                        <td>{res.loseAmount}</td>
                      </tr>
                    );
                  }
                } else if (
                  myBetsSelect == "fancyBet" ||
                  myBetsSelect == "sportsbook"
                ) {
                  if (res.type == "No") {
                    return (
                      <tr className="lay_bg_tuch">
                        <td className="match_oddds d-block">
                          <button
                            style={{
                              border: "none",
                              background: "#faa9ba",
                              borderRadius: "5px",
                              marginRight: "8px",
                              padding: "3px 7px",
                              fontSize: "11px",
                            }}
                          >
                            No
                          </button>
                          <strong style={{ fontSize: "11px" }}>
                            {res.matchName}
                          </strong>{" "}
                          <h5
                            className="d-block mt-1 ms-5"
                            style={{ fontSize: "11px" }}
                          >
                            <small>Match Odds</small>
                          </h5>
                        </td>
                        <td>
                          {res.betRun}/{res.bhav}
                        </td>
                        <td>{res.amount}</td>
                        <td>{res.loseAmount}</td>
                      </tr>
                    );
                  }
                }
              })}
            </tbody>
          </Table>
        </div>
      )} */}
    </div>
  );
};

export default OpenBets;
