import { cond, isEmpty } from "lodash";
import React, { Fragment, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
const FancyTabs = ({
  fancyCategoryTabArray,
  renderFancyLay,
  renderFancyBack,
  fancyBetSlipObj,
  renderFancySlip,
  sportType,
  amountRangeFancy,
}) => {
  return (
    <div>
      <div className="special-bet-table">
        <div className="space-bet">
          <Table className="fancy-w-tab">
            <tbody>
              <tr className="bet-all bet-thead">
                <td colSpan="3" width="60%"></td>
                <td className="p-0 border-0">
                  <table>
                    <tr>
                      <td width="8%" style={{ backgroundColor: "#faa9ba" }}>
                        <strong className="text-center d-block">
                          {fancyCategoryTabArray.length > 0 ? "No" : ""}
                        </strong>
                      </td>
                      <td width="8%" style={{ background: "#72bbef" }}>
                        {" "}
                        <strong className="text-center d-block">
                          {fancyCategoryTabArray.length > 0 ? "Yes" : ""}
                        </strong>
                      </td>
                    </tr>
                  </table>
                </td>

                <td
                  colSpan="2"
                  className="d-flex justify-content-center"
                  style={{ color: "#577c94" }}
                >
                  Min - Max
                </td>
              </tr>
              {fancyCategoryTabArray.length > 0 &&
                fancyCategoryTabArray.map((data, index) => {
                  if (
                    data?.odds?.ms == 4 ||
                    data?.odds?.rt?.length == 0 ||
                    data?.odds?.ms == 2 ||
                    isEmpty(data?.odds?.rt)
                  ) {
                    return null;
                  } else {
                    let newPosition = "";
                    if (fancyBetSlipObj?.fancyName === data?.fancyName) {
                      if (fancyBetSlipObj?.ib) {
                        if (Math.abs(data?.position) > 0) {
                          let backLoss =
                            Number(fancyBetSlipObj?.activePrice) +
                            Number(data.position);
                          newPosition = Number(backLoss);
                        } else {
                          let backLoss = Number(fancyBetSlipObj?.activePrice);
                          newPosition = Number(backLoss);
                        }
                      } else {
                        if (Math.abs(data?.position) > 0) {
                          let backProfit =
                            (Number(fancyBetSlipObj?.activePrice) *
                              Number(fancyBetSlipObj?.pt)) /
                            100;
                          newPosition =
                            Number(backProfit) + Number(data.position);
                        } else {
                          let backProfit =
                            (Number(fancyBetSlipObj?.activePrice) *
                              Number(fancyBetSlipObj?.pt)) /
                            100;
                          newPosition = Number(backProfit);
                        }
                      }
                    }
                    let condition =
                      fancyBetSlipObj?.fancyName === data?.fancyName &&
                      Math.abs(newPosition) > 0;
                    newPosition = -newPosition;
                    return (
                      <Fragment key={data?._id}>
                        <tr className="bet-all  bet-thead">
                          <td className="d-flex justify-content-between align-items-center mobile_large">
                            <strong className="mb-0 pt-0 d-block">
                              {data?.fancyName}
                            </strong>
                            <div className="runs_value d-flex align-items-center justify-content-between">
                              <div className="runs_value_left">
                                {Math?.abs(data?.position) > 0 && (
                                  <span
                                    style={{
                                      color:
                                        data?.position > 0 ? "green" : "red",
                                      marginLeft: "10px",
                                      marginRight: "8px",
                                    }}
                                  >
                                    ({Math.abs(data?.position)})
                                  </span>
                                )}

                                {Math?.abs(newPosition) > 0 &&
                                  condition &&
                                  fancyBetSlipObj?.fancyName ===
                                    data?.fancyName && (
                                    <span
                                      style={{
                                        color:
                                          newPosition > 0 ? "green" : "red",

                                        marginRight: "10px",
                                      }}
                                      className="d-flex align-items-center"
                                    >
                                      <FaArrowRight style={{marginRight:"3px"}}/> ({Math.abs(newPosition)})
                                    </span>
                                  )}
                              </div>

                              {Math.abs(data?.position) > 0 && (
                                <button
                                  className="yellow_btn"
                                  style={{
                                    marginRight: "10px",
                                    background:
                                      "linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)",
                                    color: "white",
                                    fontWeight: "600",
                                  }}
                                  onClick={(e) => {
                                    javascript: window.open(
                                      `/DownlinePnl-Fancy/${sportType}/${data?.eventId}/${data?.marketId}/${data?.selectionId}`,
                                      "_blank",
                                      "height=900,width=1200"
                                    );
                                  }}
                                >
                                  Book
                                </button>
                              )}
                            </div>
                          </td>

                          <td colSpan="2" className=""></td>
                          <td className="for_saspend">
                            <table>
                              <tr>
                                {renderFancyLay(data)}

                                {/* LAY START */}
                                {renderFancyBack(data)}
                              </tr>
                            </table>
                            {data?.odds?.rt?.length === 0 ? (
                              <dd
                                id="suspend"
                                className="suspend-fancy"
                                style={{ display: "flex" }}
                              >
                                <p id="info">Suspend</p>
                              </dd>
                            ) : data?.odds?.ms == 1 ? (
                              ""
                            ) : (
                              <dd
                                id="suspend"
                                className="suspend-fancy"
                                style={{ display: "flex" }}
                              >
                                <p id="info">
                                  {" "}
                                  {data?.odds?.ms == 9
                                    ? "Ball Running"
                                    : data?.odds?.ms == 2
                                    ? "In Active"
                                    : data?.odds?.ms == 3
                                    ? "Suspend"
                                    : data?.odds?.ms == 4
                                    ? "Closed"
                                    : "Suspend"}
                                </p>
                              </dd>
                            )}
                          </td>
                          <td className="predict fancy-minmax">
                            {" "}
                            {amountRangeFancy?.min?.toFixed(2)} -{" "}
                            {amountRangeFancy?.max?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          {/* BET SLIP CARD */}
                          {data?.selectionId == fancyBetSlipObj?.ri && (
                            <>{renderFancySlip(fancyBetSlipObj)}</>
                          )}
                          {/* BET SLIP CARD END */}
                        </tr>
                      </Fragment>
                    );
                  }
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default FancyTabs;
